<template>
  <div id="ssc-appt-form">
    <template v-if="franchiseIsSet && !loading">
      <!-- FRANCHISE INFO -->
      <SscApptFranchiseInfo v-if="franchise" class="mt-2" :franchise="franchise" />

      <!-- REFERENCE ID -->
      <div
        v-if="apptId"
        class="mb-3 d-inline-block text-warning text-bold border-warning p-2"
      >
        Updating appointment #{{ apptId }}
      </div>

      <!-- LEAD INFO -->
      <transition name="fade" mode="out-in">
        <SscLeadHeader v-if="$store.getters.lead.status" :leadId="leadId" />
      </transition>

      <!-- ENROLLMENT FORM -->
      <b-form
        ref="form-ssc-appt"
        @submit.prevent="formCheck"
        @reset.prevent="onFormReset"
        autocomplete="off"
      >
        <b-row>
          <b-col lg="3" class="animated fadeIn">
            <!-- b-form-group
              :label="$t('label.country')"
              label-sr-only
            >
              <BaseFormSelectCountry v-model="appt.country" />
            </b-form-group -->
            <!-- CLIENT(s) -->
            <SscApptClient
              ref="clients"
              v-model="clients"
              :franchiseCode="franchise.franchiseCode"
              :defaultRegFee="defaultRegFee"
              @registration-fee="appt.registrationFee = $event"
            />

            <!-- REGISTRATION FEE -->
            <fieldset>
              <legend>{{ $t('label.registrationFee') }}</legend>
              <b-form-group
                label="Registration Fee"
                label-for="registration-fee"
                label-sr-only
              >
                <b-input-group prepend="$">
                  <b-form-input
                    id="registration-fee"
                    v-model="appt.registrationFee"
                    type="number"
                    step=".01"
                    min="0"
                  ></b-form-input>
                </b-input-group>
              </b-form-group>
              <b-form-group
                id="fieldset-registration_fee_notes"
                label="Registration Fee Notes (optional)"
                label-for="registration_fee_notes"
                label-sr-only
              >
                <b-form-textarea
                  id="service_group_notes"
                  v-model="appt.registrationFeeNotes"
                  placeholder="Registration Fee Notes (optional)"
                  rows="2"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </fieldset>

            <!-- MARKETING -->
            <fieldset>
              <legend>Marketing</legend>
              <b-form-group label="Marketing" label-for="marketing" label-sr-only>
                <b-form-select
                  id="marketing"
                  v-model="appt.promotion"
                  :options="marketingList"
                  :required="isEnrollment"
                >
                  <template #first>
                    <b-form-select-option :value="null" disabled
                      >-- Source --</b-form-select-option
                    >
                  </template>
                  <template #default>
                    <b-form-select-option
                      :value="marketingNotListed.value"
                      class="text-warning"
                      >{{ marketingNotListed.text }}</b-form-select-option
                    >
                  </template>
                </b-form-select>
              </b-form-group>
            </fieldset>
          </b-col>

          <b-col class="animated fadeIn">
            <!-- PRIMARY CONTACT -->
            <fieldset>
              <legend>Primary Contact</legend>

              <b-form-row>
                <b-col sm="4">
                  <b-form-group
                    label="Ralationship"
                    label-for="pc-relationship"
                    label-sr-only
                  >
                    <b-form-select
                      id="pc-relationship"
                      v-model="primaryContact.relationship"
                      :options="clientContactRelOpts"
                      :required="isEnrollment"
                    >
                      <template #first>
                        <b-form-select-option :value="null" disabled
                          >-- Relationship --</b-form-select-option
                        >
                      </template>
                    </b-form-select>
                  </b-form-group>
                </b-col>

                <template v-if="primaryContact.relationship !== 'Self'">
                  <b-col sm="4">
                    <b-form-group label="First Name" label-for="pc-fname" label-sr-only>
                      <b-form-input
                        id="pc-fname"
                        type="text"
                        v-model="primaryContact.fname"
                        placeholder="First Name"
                        required
                        @blur="onPCBlur"
                      />
                    </b-form-group>
                  </b-col>

                  <b-col sm="4">
                    <b-form-group label="Last Name" label-for="pc-lname" label-sr-only>
                      <b-form-input
                        id="pc-lname"
                        type="text"
                        v-model="primaryContact.lname"
                        placeholder="Last Name"
                        required
                        @blur="onPCBlur"
                      />
                    </b-form-group>
                  </b-col>
                </template>
              </b-form-row>

              <!-- primary contact email / phone -->
              <b-form-row>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group
                    label="Email"
                    label-for="primaryContact-email"
                    label-sr-only
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-icon icon="envelope"></b-icon>
                      </b-input-group-prepend>
                      <b-form-input
                        id="primaryContact-email"
                        type="email"
                        v-model="primaryContact.email"
                        placeholder="Email"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group
                    label="Mobile Phone"
                    label-for="primaryContact-phone-mobile"
                    label-sr-only
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-icon icon="phone"></b-icon>
                      </b-input-group-prepend>
                      <BaseFormPhone
                        v-model="primaryContact.phones.mobile.number"
                        id="primaryContact-phone-mobile"
                        placeholder="Mobile Phone"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group
                    label="Home Phone"
                    label-for="primaryContact-phone-home"
                    label-sr-only
                  >
                    <b-input-group class="mb-2">
                      <b-input-group-prepend is-text>
                        <b-icon icon="telephone"></b-icon>
                      </b-input-group-prepend>
                      <BaseFormPhone
                        v-model="primaryContact.phones.home.number"
                        id="primaryContact-phone-home"
                        placeholder="Home Phone"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="3">
                  <b-form-group
                    label="Work Phone"
                    label-for="primaryContact-phone-work"
                    label-sr-only
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text>
                        <b-icon icon="telephone-fill"></b-icon>
                      </b-input-group-prepend>
                      <BaseFormPhone
                        v-model="primaryContact.phones.work.number"
                        id="primaryContact-phone-work"
                        placeholder="Work Phone"
                      />
                    </b-input-group>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </fieldset>

            <!-- PRIMARY ADDRESS -->
            <fieldset>
              <legend>Primary Address</legend>
              <b-form-row>
                <b-col sm="6">
                  <b-row>
                    <b-col sm="8">
                      <label class="sr-only" for="appt-street1"></label>
                      <b-form-input
                        id="appt-street1"
                        type="text"
                        v-model="appt.street1"
                        placeholder="Street"
                        class="mb-2"
                        :required="isEnrollment"
                      />
                    </b-col>
                    <b-col sm="4">
                      <label class="sr-only" for="appt-street2"></label>
                      <b-form-input
                        id="appt-street2"
                        type="text"
                        v-model="appt.street2"
                        placeholder="Apt"
                        class="mb-2"
                      />
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col sm="4">
                      <label class="sr-only" for="appt-city"></label>
                      <b-form-input
                        id="appt-city"
                        type="text"
                        v-model="appt.city"
                        placeholder="City"
                        class="mb-2"
                        :required="isEnrollment"
                      />
                    </b-col>

                    <b-col sm="4">
                      <label class="sr-only" for="appt-state"></label>
                      <BaseFormSelectState :country="appt.country" v-model="appt.state" />
                    </b-col>

                    <b-col sm="4">
                      <label class="sr-only" for="appt-zipCode"></label>
                      <BaseFormPostalCode
                        v-model="appt.zipCode"
                        @pc-data="
                          (e) => {
                            if (e.city) {
                              appt.city = e.city
                              appt.state = e.state
                            }
                          }
                        "
                      />
                    </b-col>
                  </b-row>
                </b-col>
                <b-col class="align-baseline">
                  <!-- LOOK UP CLIENT ADDRESS -->
                  <b-button
                    size="sm"
                    variant="secondary"
                    class="mb-2"
                    :disabled="!(appt.street1 && appt.zipCode)"
                    @click="googleClientAddress"
                    block
                    >View Address in Google</b-button
                  >
                </b-col>
              </b-form-row>
            </fieldset>

            <!-- FULL DESCRIPTION OF CLIENT NEEDS -->
            <fieldset v-if="academicDescriptions.length">
              <legend>{{ $t('label.academicDescription') }}</legend>
              <template v-for="(description, index) in academicDescriptions">
                <b-form-group
                  v-if="description.active"
                  :label="
                    $t('label.academicDescriptionFor', {
                      name: description.client.fname
                        ? `${description.client.fname} ` +
                          (description.client.lname || '')
                        : '...',
                    })
                  "
                  :label-for="'client-' + index + '-description'"
                  :key="index"
                  :label-sr-only="academicDescriptions.length === 1"
                >
                  <b-form-textarea
                    :id="'client-' + index + '-description'"
                    rows="3"
                    v-model="academicDescriptions[index].description"
                    max-rows="20"
                    class="mb-2"
                    :placeholder="$t('label.academicDescriptionPlaceholder')"
                  />
                </b-form-group>
              </template>
            </fieldset>

            <!-- DIRECTIONS / NOTES -->
            <fieldset v-if="academicDescriptions.length">
              <legend>{{ $t('label.directions') }}/{{ $t('label.notes') }}</legend>

              <label class="sr-only" for="directions"
                >Directions and Additional Notes</label
              >
              <b-form-textarea
                id="directions"
                v-model="appt.directions"
                rows="3"
                max-rows="20"
                class="mb-2"
                placeholder="Directions / Notes"
              />
            </fieldset>

            <!-- APPT TYPE -->
            <b-input-group class="mb-2 border-info" prepend="Entry Type:">
              <b-form-select
                id="entry-type"
                v-model="appType"
                :options="entryTypeOptions"
              ></b-form-select>
            </b-input-group>
          </b-col>
        </b-row>

        <!-- SCHEDULER -->
        <transition name="fade" mode="out-in">
          <div v-if="showScheduler" class="my-2 p-2 border-info">
            <h4 class="text-center text-info">
              {{ $t('label.enrollmentVisit') }}:
              <span class="highlight"
                >{{
                  $d(`${appt.startDate}T${appt.startTime}`).format('YYYY MMM D h:mma')
                }}: {{ defaultHours }}</span
              >
              {{ $tc('common.hours', defaultHours) }}
            </h4>

            <scheduler
              ref="scheduler"
              resourceLabel="ECs"
              :franchiseCode="franchise.franchiseCode"
              :editableId="editableId"
              :resources="scheduleResources"
              :events="schedulerAppointments"
              :date="schedulerIniScrollDate"
              :iniScrollTime="schedulerIniScrollTime"
              :eventDuration="defaultHours"
              @select="setUserDatetime"
              @event-drop="() => null"
              @dates-set="getSchedulerAppointments"
            />
          </div>
        </transition>

        <BaseFormButtons
          @form-button-submit="formCheck"
          @form-button-reset="onFormReset"
          size="md"
          no-cancel
        />

        <!-- div class="d-flex justify-content-between align-items-center mt-4">
          <b-button type="reset" variant="danger"><i class="fa fa-times" aria-hidden="true"></i> Reset</b-button>
          <b-button type="submit" variant="success"><i class="fa fa-check" aria-hidden="true"></i> Submit</b-button>
        </div -->
      </b-form>

      <!-- generic -->
      <b-modal id="modalInfo" :title="modalInfoTitle" ok-only>
        <div class="my-4" v-html="modalInfoContent"></div>
      </b-modal>

      <!-- existing lead -->
      <b-modal
        id="leads-found-list"
        title="Leads matching criteria were found..."
        v-model="leadsModelShow"
        hide-footer
      >
        <p v-for="lead in leads" :key="lead.id">
          <a
            href="javascript:;"
            @click="setLeadData(lead.id)"
            v-b-tooltip.hover
            title="Load this lead"
          >
            {{ lead.fname }} {{ lead.lname
            }}<span v-if="lead.state"> - {{ lead.city }}, {{ lead.state }}</span>
            {{ lead.email }} / {{ lead.phone }}
          </a>
        </p>
      </b-modal>

      <!-- ENTRY CONFIRMATION -->
      <b-modal
        ref="modalSscConfirmation"
        ize="xl"
        :title="apptTypeText"
        :ok-disabled="!isEnrollment && !appt.resultReason"
        @ok="formSubmit()"
      >
        <div>
          <p v-if="appType === 'enrollment_visit'">
            <span v-if="apptDateTime"
              ><b>Date/time:</b>
              {{ $d(apptDateTime).format('dddd, MMMM D [at] hh:mmA') }}</span
            ><br />
            <b>Consultation Address:</b> {{ consultationAddress }}<br />
            <b>EC:</b>
            {{ assignedEC }}
          </p>
          <p v-for="thisClient in clients" :key="thisClient.id">
            <b>Student:</b> {{ thisClient.fname }} {{ thisClient.lname
            }}<!-- <br />
            {{
              academicDescriptions[index] ? academicDescriptions[index].description : ''
            }} -->
            <!-- span v-if="thisClient.serviceGroupData"><b>Needs:</b> <span v-if="!selectedService">
              {{ thisClient.serviceGroupData.customName }}</span> {{ selectedService }} tutoring<br /></span -->
          </p>
          <hr />
          <b>Primary Contact:</b> {{ primaryContact.fname }}
          {{ primaryContact.lname }}
          <span v-if="primaryContact.relationship"
            >({{ primaryContact.relationship }})</span
          ><br />
          <span v-if="primaryContact.email"><b>Email:</b> {{ primaryContact.email }}</span
          ><br />
          <b>Phone:</b>
          <span v-if="primaryContact.phones.mobile">
            {{ primaryContact.phones.mobile.number }} (mobile)</span
          >
          <span v-if="primaryContact.phones.home">
            {{ primaryContact.phones.home.number }} (home)</span
          >
          <span v-if="primaryContact.phones.work">
            {{ primaryContact.phones.work.number }} (work)</span
          >
        </div>

        <template v-if="appType == 'client_lead'">
          <b-form-select
            v-model="appt.resultReason"
            :options="callResultReasonOptions"
            class="my-3"
          />
          <b-form-textarea
            v-model="appt.notes"
            :rows="3"
            :max-rows="6"
            placeholder="Reason notes (optional)"
          ></b-form-textarea>
        </template>

        <b-form-textarea
          v-if="appType == 'enrollment_visit_canceled'"
          v-model="appt.notes"
          :rows="3"
          :max-rows="6"
          placeholder="Reason for cancellation"
        ></b-form-textarea>
      </b-modal>
    </template>

    <template v-else>
      <p v-if="!franchiseCode" class="text-danger text-center m-4">
        A franchise code has not been activated.
      </p>
      <p v-else class="text-info text-center m-4">Loading...</p>
      <b-row>
        <b-col class="text-right"
          ><b-button type="reset" variant="danger">Reset</b-button></b-col
        >
      </b-row>
    </template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { sscConstants } from './sscConstants'
// import FirstSearch from './FirstSearch'
// import FormApptServiceGroup from "@/components/FormApptServiceGroup"
import SscApptFranchiseInfo from './~SscApptFranchiseInfo.vue'
import SscLeadHeader from './~SscLeadHeader.vue'
import Scheduler from './~Scheduler.vue'
import SscApptClient from './~SscApptClient.vue'
// import Treeselect from "@riophae/vue-treeselect"
// import vSelect from "vue-select"
// import { IMaskComponent } from "vue-imask"
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { constants } from '@/shared/constants'

const SSC_ENROLLMENT_DURATION_DEFAULT = '1'
const STAFF_GROUP = 'ec'
const STAFF_STATUS_ACTIVE = 2
const PC_RELATIONSHIP_SELF = 'Self'
// const SERVICE_GROUP_OPTION_NULL = [{value: null, text: 'Service Group'}]

export default {
  name: 'SscAppt',
  components: {
    // FirstSearch,
    // FormApptServiceGroup,
    SscApptFranchiseInfo,
    SscLeadHeader,
    SscApptClient,
    // Treeselect,
    // vSelect,
    Scheduler,
    // "imask-input": IMaskComponent,
  },
  props: {
    clientId: {
      type: Number,
      default: null,
    },
    leadId: {
      type: Number,
      default: null,
    },
    postalCode: {
      type: String,
      default: null,
    },
    franchiseCode: {
      default: null,
    },
    referenceId: {
      type: Number,
      default: null,
    },
    apptData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    loading: false,
    errors: [],
    scheduleResources: [],

    marketingNotListed: {
      value: '/api/franchise_promotions/1194',
      text: 'NOT LISTED',
    },

    queryPostalCode: null,
    defaultTimeZone: null,
    defaultHours: 0,
    defaultRegFee: 0,
    serviceAutoLoadOpts: false,
    marketing: [],
    primaryContact: {},
    clients: [],
    clientGroupId: null,
    academicDescriptions: [],
    leadsModelShow: false,
    entryTypeOptions: sscConstants.SAVE_OPTIONS_NEW,
    callResultReasonOptions: sscConstants.SSC_RESULT_OPTIONS,
    currentSchedulerDate: null,
    /**
     * appointments details
     */
    apptId: null,
    appType: 'enrollment',
    appt: sscConstants.DEFAULT_APPT,
    apptStaff: [],
    assignedEC: null,
    /**
     * existing timeline appointments
     */
    schedulerAppointments: [],
    schedulerIniScrollDate: null,
    schedulerIniScrollTime: '10:00',
    /**
     * Info modal
     */
    modalInfoTitle: null,
    modalInfoContent: null,
  }),
  computed: {
    ...mapGetters([
      'activeFranchiseGroupServices',
      'franchise',
      'franchiseSchools',
      'franchiseStaff',
      'lead',
      'leads',
      'serviceGroups',
      'group',
      'clientContactRelOpts',
    ]),
    isNewAppt() {
      return !this.apptId
    },
    editableId() {
      return this.referenceId || null
    },
    showScheduler() {
      return this.appType === 'enrollment_visit'
    },
    events() {
      // add existing appointments
      /* 
      let startDatetime = this.$d().format()
      const schedulerAppointments = [...this.schedulerAppointments].map((e) => {
        startDatetime = e.startDate + "T" + e.startTime
        let resource = e.assigned ? e.assigned[0].staff : null
        return {
          id: e.id,
          resourceId: resource,
          title: `${e.client.fname} ${e.client.lname}`,
          start: startDatetime,
          end: this.$d(startDatetime).add(e.duration, "hours").format()
        }
      }) */
      // add new appointment
      /* if (
        !this.apptId &&
        this.appt.startDate &&
        this.appt.startTime
      ) {
        startDatetime = this.$d(this.appt.startDate + "T" + this.appt.startTime).format()
        let resourceId = this.apptStaff.length ? this.apptStaff[0] : null
        schedulerAppointments.push({
          id: 'new',
          title: '...',
          resourceId: resourceId,
          start: startDatetime,
          end: this.$d(startDatetime).add(this.defaultHours, "hour").format(),
        })
      } */
      // return schedulerAppointments
      return this.schedulerAppointments
    },
    franchiseIsSet() {
      return this.franchise.franchiseCode > 0
    },
    isEnrollment() {
      return ['enrollment_visit', 'enrollment'].includes(this.appType)
    },
    apptTypeText() {
      let apptType = this.entryTypeOptions.find((e) => e.value === this.appType)
      // console.log('apptTypeText', apptType)
      return typeof apptType !== 'undefined' ? apptType.text : 'Unknown type'
    },
    consultationAddress() {
      return (
        this.appt.street1 +
        (this.appt.street2 ? ' ' + this.appt.street2 : '') +
        ' ' +
        this.appt.city +
        ' ' +
        this.appt.state +
        ' ' +
        this.appt.zipCode
      )
    },
    apptDateTime() {
      if (!this.appt.startDate) {
        return null
      }
      const startTime = this.appt.startTime.split('T')
      return this.appt.startDate.split('T')[0] + 'T' + (startTime[1] || startTime[0])
    },
    // serviceList() {
    //   const ret = []
    //   if (!this.activeFranchiseGroupServices.length) {
    //     return [
    //       {
    //         id: "0",
    //         label: "Other",
    //         children: [
    //           {
    //             id: "0",
    //             label: "Other",
    //           },
    //         ],
    //       },
    //     ]
    //   }

    //   let c = 0
    //   let categoryIndex
    //   let serviceIndex

    //   for (const i in this.activeFranchiseGroupServices) {
    //     categoryIndex = ret.findIndex(
    //       ({ id }) =>
    //         id === this.activeFranchiseGroupServices[i].service.category.id
    //     )

    //     if (categoryIndex === -1) {
    //       ret[c] = {
    //         id: this.activeFranchiseGroupServices[i].service.category.id,
    //         label: this.activeFranchiseGroupServices[i].service.category.name,
    //         children: [],
    //       }
    //       categoryIndex = c
    //       c++
    //     }

    //     serviceIndex = ret[categoryIndex].children.findIndex(
    //       ({ id }) => id === this.activeFranchiseGroupServices[i].service.id
    //     )

    //     if (serviceIndex === -1) {
    //       ret[categoryIndex].children.push({
    //         id: this.activeFranchiseGroupServices[i].service.id,
    //         label: this.activeFranchiseGroupServices[i].service.name,
    //       })
    //     }
    //   }

    //   // if (ret.length === 1 && ret[0].children.length === 1) {
    //   //   this.service = [ret[0].children[0]]
    //   // }

    //   return ret
    // },
    marketingList() {
      const ret = []
      if (!this.marketing.length) {
        return ret
      }
      let c = 0
      let categoryIndex
      let marketingIndex
      for (const i in this.marketing) {
        categoryIndex = ret.findIndex(
          ({ categoryId }) => categoryId === this.marketing[i].category.id
        )
        if (categoryIndex === -1) {
          ret[c] = {
            categoryId: this.marketing[i].category.id,
            label: this.marketing[i].category.name,
            options: [],
          }
          categoryIndex = c
          c++
        }
        marketingIndex = ret[categoryIndex].options.findIndex(
          ({ value }) => value === this.marketing[i].id
        )
        if (marketingIndex === -1) {
          let text = this.marketing[i].resource ? this.marketing[i].resource.source : null
          if (typeof this.marketing[i].resourceExtra !== 'undefined') {
            text = text + ` (${this.marketing[i].resourceExtra})`
          }
          ret[categoryIndex].options.push({
            value: this.marketing[i]['@id'],
            text: `${this.marketing[i].category.name}: ${text}`,
          })
        }
      }
      return ret
    },
  },
  filters: {
    bracketText(text) {
      text.replace(/^\[[^\]]\]/g, `<span>$1</span>`)
    },
  },
  watch: {
    franchiseStaff: {
      // deep: true,
      handler() {
        this.scheduleResources = this.franchiseStaff.map((e) => ({
          id: e.employee['@id'],
          title: `${e.employee.profile.fname} ${e.employee.profile.lname}`,
          businessHours: e.employee.availability.map((a) => ({
            startTime: a.startTime,
            endTime: a.endTime,
            daysOfWeek: [a.day],
          })),
        }))
        this.scheduleResources.push({
          id: null,
          title: 'N/A',
        })
      },
    },
    // academicDescriptions(n, o) {
    //   console.log('academicDescriptions', n, o)
    // },
    franchise: {
      handler(obj) {
        this.appt.franchiseCode = obj.franchiseCode
      },
      deep: true,
    },
    referenceId(val) {
      this.primaryContact = { ...sscConstants.DEFAULT_PC }
      this.$emit('lead-id', null)
      this.$store.dispatch('resetLead')
      this.setApptData(val)
    },
    /* serviceGroupData: {
      handler (obj) {
        this.appt.quotedRate = parseFloat(obj.price)
        this.appt.quotedRateType = obj.price_divisor
      },
      deep: true
    }, */
    client: {
      handler(obj) {
        this.appt.client_id = obj.id
        this.appt.street1 = obj.street1
        this.appt.street2 = obj.street2
        this.appt.city = obj.city
        this.appt.state = obj.state
        this.appt.zipCode = obj.zipCode
        this.appt.country = obj.country
      },
      deep: true,
    },
    clients: {
      handler() {
        this.setClients()
      },
      deep: true,
    },
  },
  created() {
    // @XXX testing
    // this.setPostalCode('33648')
    // this.setClientData(49993)
    // this.setFranchiseData(1900)
    this.primaryContact = { ...sscConstants.DEFAULT_PC }
    if (this.referenceId && this.referenceId !== null) {
      // set appointment data
      this.setApptData(this.referenceId)
    } else if (this.clientId && this.clientId !== null) {
      // set client data
      this.setClientData(this.clientId)
    } else if (this.leadId && this.leadId !== null) {
      // set lead data
      this.setLeadData(this.leadId)
    } else if (this.postalCode && this.postalCode !== null) {
      // set postal code data
      this.setPostalCode(this.postalCode)
      this.setFranchiseData(this.franchiseCode)
    } else if (this.franchiseCode && this.franchiseCode !== null) {
      // set franchise data
      this.setFranchiseData(this.franchiseCode)
    } else if (this.apptData) {
      // set appointment from data
      this.setApptFromData(this.apptData)
    }

    if (!this.referenceId) {
      this.setCurrentDateTime()
    }

    if (this.appt.zipCode && !this.appt.state) {
      this.getZipCodeData(this.appt.zipCode).then(() => {
        if (this.$store.getters.zipCodeData) {
          this.appt.city = this.$store.getters.zipCodeData.city
          this.appt.state = this.$store.getters.zipCodeData.state
        }
      })
    }
  },
  destroyed() {
    this.resetAppointments()
    this.resetClient()
    this.resetClientContacts()
    this.resetFranchise()
    this.resetFranchiseServiceGroups()
    this.resetLead()
    this.resetLeads()
    this.resetSscNotes()
  },
  methods: {
    ...mapActions([
      'getActiveFranchiseGroupServices',
      'getClientAppointment',
      'getClientAppointments',
      'getFranchise',
      'getFranchiseMarketing',
      'getFranchiseSchools',
      'getFranchiseServiceGroups',
      'getFranchiseStaff',
      'getStaffEvents',
      'resetAppointments',
      'resetClient',
      'resetClientContacts',
      'resetFranchise',
      'resetFranchiseServiceGroups',
      'resetLead',
      'resetLeads',
      'resetSscNotes',
      'getZipCodeData',
      'postGroup',
      'postGroupContact',
      'putGroupContact',
      'postGroupClient',
    ]),
    /**
     * sets the associated user ID and datetime of the appointment
     */
    setUserDatetime(payload) {
      // const prevAppt = this.appt
      // const prevApptStaff = this.apptStaff
      // set appt staff
      this.apptStaff = []
      this.assignedEC = null
      if (!['null', null].includes(payload.resource._resource.id)) {
        this.apptStaff.push(payload.resource._resource.id)
        this.assignedEC = payload.resource._resource.title
      }
      this.appt.startDate = this.$d(payload.startStr).format('YYYY-MM-DD')
      this.appt.startTime = this.$d(payload.startStr).format('HH:mm:ss')
      this.appt.duration = this.defaultHours

      // udpate in schedulerAppointments list
      let startDatetime = this.$d(
        this.appt.startDate + 'T' + this.appt.startTime
      ).format()
      let endDatetime = this.$d(startDatetime).add(this.defaultHours, 'hour').format()
      let resourceId = this.apptStaff.length ? this.apptStaff[0] : null
      const i = this.schedulerAppointments.findIndex((e) => e.id === this.apptId)

      if (i !== -1) {
        // exists in schedulerAppointments - update same day
        this.schedulerAppointments[i].resourceId = resourceId
        this.schedulerAppointments[i].start = startDatetime
        this.schedulerAppointments[i].end = endDatetime
      } else {
        // not in schedulerAppointments (new or day change) - add
        this.schedulerAppointments.push({
          id: this.apptId,
          title: `${this.clients[0].fname} ${this.clients[0].lname}`,
          resourceId: resourceId,
          start: startDatetime,
          end: endDatetime,
        })
      }

      // this.setCurrentAppointment()
      // this.appt = prevAppt
      // this.apptStaff = prevApptStaff
    },
    setClients() {
      // removal
      this.academicDescriptions.forEach((ad, i) => {
        if (!ad.client || !ad.client.key) {
          this.academicDescriptions.splice(i, 1)
        } else {
          this.academicDescriptions[i].active = false
        }
      })
      // inclusion
      this.clients.forEach((c) => {
        const existingIndex = this.academicDescriptions.findIndex(
          (ad) => ad.client && ad.client.key && ad.client.key === c.key
        )
        if (existingIndex !== -1) {
          this.academicDescriptions[existingIndex].client = c
          this.academicDescriptions[existingIndex].active = true
        } else {
          this.academicDescriptions.push({
            client: c,
            description: null,
            active: true,
          })
        }
      })
    },
    setGroup(val) {
      this.appt.service_group_id = val
    },
    setPostalCode(val) {
      let r = val.split(' | ')
      this.queryPostalCode = r[0]
      this.appt.zipCode = this.postalCode // @XXX

      if (this.appt.zipCode === null) {
        this.appt.zipCode = r[0]
        this.appt.state = r[1]
        this.appt.city = r[2]
      }
    },
    /**
     * load franchise data
     */
    async setFranchiseData(franchiseCode) {
      // console.log("SscAppt::setFranchiseData()", franchiseCode)
      try {
        const getFranchise = await this.getFranchise(franchiseCode)
        if (!this.franchise.id) {
          throw new Error('No associated franchise data found for #' + franchiseCode)
        }

        this.$emit('franchise-code', this.franchise.franchiseCode)
        this.appt.country = this.franchise.country
        await this.getFranchiseServiceGroups(this.franchise.franchiseCode)
        await this.getFranchiseStaff({
          franchiseCode: this.franchise.franchiseCode,
          type: STAFF_GROUP,
          status: STAFF_STATUS_ACTIVE,
        })
        this.defaultRegFee = parseFloat(
          await this.getFranchiseSetting('general', 'company', 'registration_fee', 0)
        )
        this.defaultHours = await this.getFranchiseSetting(
          'appointments',
          'consultation',
          'default_hrs',
          SSC_ENROLLMENT_DURATION_DEFAULT
        )
        this.defaultTimeZone = await this.getFranchiseSetting(
          'general',
          'company',
          'time_zone',
          null
        )
        await this.setMarketingData()
        // await this.getSchedulerAppointments({ start: this.$d()./* subtract(15, 'days'). */format() })
        return getFranchise
      } catch (error) {
        console.error(error)
      }
    },
    /**
     * loads franchise marketing options
     */
    async setMarketingData() {
      await this.getFranchiseMarketing(this.franchise.franchiseCode)
      this.marketing = this.franchise.marketing
    },
    /**
     * load client data from lead record
     */
    async setLeadData(leadId) {
      try {
        await this.$store.dispatch('getLead', leadId)
        if (typeof this.$store.getters.lead.id === 'undefined') {
          this.$emit('reset', 'invalid lead ID passed to setLeadData()')
        }
        const lead = { ...this.$store.getters.lead }
        if (typeof this.franchise.franchiseCode === 'undefined') {
          await this.setFranchiseData(lead.franchiseCode)
        }
        // reset clients
        this.clients = []
        // set appointment data
        this.appt.street1 = lead.street1
        this.appt.street2 = lead.street2
        this.appt.city = lead.city
        this.appt.state = lead.state
        this.appt.zipCode = lead.zipCode
        this.appt.country = lead.country
        // set primary concact data
        this.primaryContact.fname = lead.fname
        this.primaryContact.lname = lead.lname
        this.primaryContact.email = lead.email
        this.primaryContact.phones.mobile.number = lead.phone

        if (lead.client) {
          await this.setClientData(lead.client.id)
        } else {
          this.clients.push({
            fname: lead.clientFname || null,
            lname: lead.clientLname || null,
          })
          this.$refs.clients.setDefaultClients(this.clients)
        }

        /* await this.$store.dispatch('getLeadNotes', {
          lead: lead['@id'],
          'order[id]': 'desc'
        }) */
      } catch (e) {
        this.$emit('reset', 'Could not retrieve requested lead data')
      }
      this.leadsModelShow = false
    },
    /**
     * load client data
     */
    async setClientData(clientId, data) {
      if (clientId === null) {
        console.error('null passed as client ID for setClientData()')
        return
      }
      try {
        if (!this.franchise.franchiseCode) {
          await this.setFranchiseData(this.franchiseCode)
        }
        const getClient = await this.$store.dispatch('getClient', clientId)
        let client = { ...this.$store.getters.client }

        // set primary contact
        this.primaryContact = { ...sscConstants.DEFAULT_PC }
        let pc
        // check Group contacts first
        if (client.groups.length > 0) {
          // find primary contact by group
          const household = client.groups.find((e) => e.group.type.id === 1) // @TODO set in constants
          if (household) {
            await this.$store.dispatch('getGroup', household.group.id)
            await this.$store.dispatch('getGroupContacts', household.group.id)
            pc =
              this.$store.getters.groupContacts.find((e) => e.data.primary === '1') ||
              this.$store.getters.groupContacts[0]
            if (pc) {
              this.primaryContact = {
                ...this.primaryContact,
                ...{ id: pc.id },
                ...pc.data,
              }
              pc.phones.forEach((e) => {
                this.primaryContact.phones[e.type] = {
                  ...constants.DEFAULT_PHONE_OBJ,
                  ...e,
                }
              })
              // set update data
              this.primaryContact.udata = { ...pc.udata }
            }
          }
        }
        // no primary contact in Groups? Check Client contacts
        if (!pc) {
          // find primary contact by client
          await this.$store.dispatch('getClientContacts', client.id)
          if (this.$store.getters.clientContacts.length) {
            pc =
              this.$store.getters.clientContacts.find((e) => e.data.primary === '1') ||
              this.$store.getters.clientContacts[0]
            if (pc) {
              this.primaryContact = {
                ...this.primaryContact,
                ...{ id: pc.id },
                ...pc.data,
              }
              pc.phones.forEach((e) => {
                this.primaryContact.phones[e.type] = e
              })
              this.primaryContact.udata = { ...pc.udata }
            }
          }
        }

        if (this.isNewAppt) {
          this.appt.street1 = client.street1
          this.appt.street2 = client.street2
          this.appt.city = client.city
          this.appt.state = client.state
          this.appt.zipCode = client.zipCode
          this.appt.country = client.country
        }

        const thisClient = {
          ...this.clients[0],
          ...{
            id: client.id,
            fname: client.fname,
            lname: client.lname,
            school: client.clientProfile.school || null,
          },
          ...(data || {}),
        }

        this.$refs.clients.setDefaultClients([thisClient])

        return getClient
      } catch (e) {
        console.error(e)
        alert('Could not retrieve requested client data')
      }
    },

    async getSchedulerAppointments(info) {
      const startDate = this.$d(info.start).format('YYYY-MM-DD')
      if (startDate === this.currentSchedulerDate) {
        return
      } // prevents infinite loop
      const schedulerDays = 0 // number of additional days to query
      const endDate = this.$d(info.start).add(schedulerDays, 'days').format('YYYY-MM-DD')
      const clientAppointments = await this.getClientAppointments({
        franchiseCode: this.franchise.franchiseCode,
        consultation: true,
        'startDate[after]': startDate,
        'startDate[before]': endDate,
      })
      // set this.schedulerAppointments
      this.schedulerAppointments = this.$store.getters.clientAppointments.map((e) => {
        let resourceExists = false
        if (e.assigned.length) {
          resourceExists =
            this.scheduleResources.findIndex((s) => s.id === e.assigned[0].staff.id) !==
            -1
        }
        let resource = resourceExists ? e.assigned[0].staff.id : null
        let startDatetime = e.startDate + 'T' + e.startTime
        return {
          id: e.id,
          resourceId: resource,
          title: `${e.client.fname} ${e.client.lname}`,
          start: startDatetime,
          end: this.$d(startDatetime).add(e.duration, 'hours').format(),
        }
      })
      this.setCurrentAppointment()
      this.currentSchedulerDate = startDate
      return clientAppointments
    },

    setCurrentAppointment() {
      if (this.isNewAppt) {
        let startDatetime = this.$d(
          this.appt.startDate + 'T' + this.appt.startTime
        ).format()
        let endDatetime = this.$d(startDatetime).add(this.defaultHours, 'hour').format()
        let resourceId = this.apptStaff.length ? this.apptStaff[0] : null
        const thisAppt = {
          id: null,
          title: '...',
          resourceId: resourceId,
          start: startDatetime,
          end: endDatetime,
        }
        this.schedulerAppointments.push(thisAppt)
      }
    },

    setAcademicDescription(description, key) {
      if (!key) key = 0
      // console.log('setAcademicDescription clients', this.clients, key, description)
      this.$set(this.academicDescriptions, key, {
        client: this.clients[key],
        active: true,
        description,
      })
    },

    async setApptData(apptId, data) {
      this.loading = true
      this.academicDescriptions = []
      try {
        this.entryTypeOptions = sscConstants.SAVE_OPTIONS_UPDATE
        this.apptId = apptId
        await this.setFranchiseData(this.franchiseCode)
        await this.getClientAppointment(apptId)

        let apptData = { ...this.$store.getters.clientAppointment }
        // if (!apptData.endTime) { }
        this.schedulerAppointments.push(apptData)
        this.clients = []

        this.appt = {
          id: apptData.id,
          type: 'enrollment_visit',
          // fullDescription: apptData.full_description,
          // quotedRate: apptData.quotedRate,
          // quotedRateType: apptData.quotedRateType,
          // serviceGroup: apptData.service_group_id,
          // services: (apptData.service instanceof Array) ? apptData.service : [apptData.service],

          directorNotes: apptData.directorNotes,
          directions: apptData.directions,
          startDate: apptData.startDate,
          startTime: apptData.startTime,
          duration: apptData.duration,
          result: apptData.result,
          resultReason: apptData.resultReason,
          notes: apptData.notes,
          street1: apptData.street1,
          street2: apptData.street2,
          city: apptData.city,
          state: apptData.state,
          zipCode: apptData.zipCode,
          country: apptData.country,
          promotion: apptData.promotion || null,
        }

        this.apptStaff.push(apptData.assigned[0].staff.id)

        this.schedulerIniScrollDate = this.appt.startDate
        this.schedulerIniScrollTime = this.appt.startTime

        const clientData = {}
        if (apptData.serviceGroup) {
          clientData.serviceGroup = apptData.serviceGroup['@id']
          clientData.serviceGroupData = this.serviceGroups.find(
            (e) => e.serviceGroup === clientData.serviceGroup
          )
          clientData.services = apptData.services.map((e) => e.id)
        }

        this.setClientData(apptData.client.id, clientData).then(() =>
          this.setAcademicDescription(apptData.fullDescription)
        )

        if (data) {
          for (let key in this.appt) {
            this.appt[key] = data[key] || this.appt[key]
          }
        }

        /* if (this.setFranchiseData(apptData.franchiseCode)) {
          this.serviceGroup = apptData.serviceGroup
        } */
      } catch (e) {
        this.apptId = null
        alert('Could not retrieve appointment data')
        console.error(e)
      }
      this.loading = false
    },

    setApptFromData(apptData) {
      this.setClientData(apptData.client.id)
      this.appt.startDate = this.$d(apptData.datetime_start, 'MMMM Do hh:mma').format()
    },

    // set info data from scheduler
    updateApptData(payload) {
      this.$log('SscAppt::updateApptData()', payload)
      if (this.isNewAppt && !isNaN(payload.id)) {
        // load existing appointment data
        this.setApptData(payload.id, payload)
      } else {
        for (let key in payload) {
          this.appt[key] = payload[key]
        }
      }
    },

    googleClientAddress() {
      if (this.appt.street1 && this.appt.zipCode) {
        window.open(
          'https://www.google.com/maps/place/' +
            this.appt.street1 +
            ',+' +
            this.appt.zipCode,
          '_blank'
        )
      }
    },

    async formSubmit() {
      try {
        let formSubmited = []
        let clientResp

        // @TODO save primary contact

        // @TODO save phone number

        // process for each client
        for (let i in this.academicDescriptions) {
          let e = this.academicDescriptions[i]
          let thisClient = e.client
          thisClient.description = e.description
          let clientData = {
            fname: thisClient.fname,
            lname: thisClient.lname,
            street1: this.appt.street1,
            street2: this.appt.street2,
            city: this.appt.city,
            state: this.appt.state,
            zipCode: this.appt.zipCode,
            timezone: this.defaultTimeZone,
            country: this.appt.country,
            email: this.primaryContact.email,
          }

          if (thisClient.id === null) {
            // create client
            clientResp = await this.$store.dispatch('postClient', clientData)
            // client created
            this.clients[i].id = clientResp.id
            thisClient['@id'] = clientResp['@id']
            await this.$store.dispatch('postFranchiseClient', {
              franchiseCode: this.franchise.franchiseCode,
              client: clientResp['@id'],
              status: '/api/client_status_options/2',
              source: this.appt.promotion,
            })
          } else {
            // update client
            clientData.id = thisClient.id
            clientResp = await this.$store.dispatch('putClient', clientData)
            thisClient['@id'] = clientResp['@id']
          }

          let thisAppt = await this.apptSave(thisClient)
          formSubmited.push(thisAppt)
        }

        await this.saveHousehold()

        this.$bvModal
          .msgBoxConfirm(
            'The entry has been saved. The reference ID is ' +
              formSubmited.map((e) => e.id).join(', '),
            {
              size: 'lg',
              buttonSize: 'sm',
              okVariant: 'success',
              okTitle: 'Done',
              cancelTitle: 'Continue Editing',
              footerClass: 'p-2',
              centered: true,
            }
          )
          .then((finished) => {
            if (finished) {
              this.formReset()
            }
          })
      } catch (e) {
        console.error(e)
        this.$bvModal.msgBoxOk(
          'There was an error saving the entry. Please check the form and try again.'
        )
      }
    },

    async saveHousehold() {
      if (!this.group.id) {
        // create group
        await this.postGroup({
          type: constants.API_IRI_GROUP_TYPES_HOUSEHOLD,
        })
      }

      // add client to group
      for (let i in this.clients) {
        let clientIndex = this.group.clients.findIndex(
          (e) => e.client.id === this.clients[i].id
        )
        if (clientIndex === -1) {
          await this.postGroupClient({
            group: this.group['@id'],
            client: this.clients[i]['@id'], // constants.API_IRI_CLIENTS + this.clients[i].id
          })
        }
      }

      // re-fetch group
      await this.$store.dispatch('getGroup', this.group.id)

      // add client contact
      if (this.primaryContact.relationship !== PC_RELATIONSHIP_SELF) {
        return this.savePrimaryContact(this.group.id)
      }
    },

    // save primary contact
    async savePrimaryContact(groupId) {
      const primaryContact = { ...this.primaryContact }
      if (!this.primaryContact.id) {
        primaryContact.franchise_code = this.franchise.franchiseCode
        primaryContact.group_pk = groupId
        const clientContact = await this.postGroupContact(primaryContact)
        this.primaryContact.id = clientContact.id
      } else {
        await this.putGroupContact(primaryContact)
      }
    },

    async apptSave(client) {
      try {
        let apptData = { ...this.appt }
        let staff = null
        let savedAppt = {}

        switch (this.appType) {
          case 'enrollment':
            apptData.statusLabel = 'complete'
            staff = this.currentUser['@id']
            apptData.result = 7
            break
          case 'follow-up':
            apptData.statusLabel = 'follow-up'
            staff = this.currentUser['@id']
            apptData.result = 7
            break
          default:
            apptData.statusLabel = 'new'
            staff = this.apptStaff['@id']
            apptData.result = 8
            break
        }

        apptData.client = client['@id']
        apptData.fullDescription = client.description
        apptData.serviceGroup = client.serviceGroup
        apptData.services = client.services.map((e) => '/api/services/' + e)
        // @TODO apptData.serviceGroupNotes = client.serviceGroupNotes
        apptData.franchiseCode = this.franchise.franchiseCode
        console.info('staff', staff)

        if (this.isNewAppt) {
          savedAppt = await this.$store.dispatch('postClientAppointment', apptData)
          console.log('apptSave postClientAppointment', savedAppt)
          this.apptId = savedAppt.id
          // @todo save appt assigned staff
        } else {
          apptData.id = this.apptId
          savedAppt = await this.$store.dispatch('putClientAppointment', apptData)
          console.log('apptSave putClientAppointment', savedAppt)
          // @todo save appt assigned staff
        }

        return savedAppt
      } catch (e) {
        console.error(e)
      }
    },
    /**
     * checks if Primary Contact is a lead
     */
    async onPCBlur() {
      if (this.pcChecked) {
        return
      }
      if (this.clientId) {
        return
      }
      if (
        this.$store.getters.lead.id ||
        !this.primaryContact.fname ||
        !this.primaryContact.lname
      ) {
        return
      }
      await this.$store.dispatch('getLeads', {
        fname: this.primaryContact.fname,
        lname: this.primaryContact.lname,
      })
      if (this.leads.length) {
        this.leadsModelShow = true
      }
      this.pcChecked = true
    },
    /**
     * check the form and display error or confirmation
     */
    formCheck() {
      // console.log("formCheck")
      this.errors = []

      if (this.appType === 'enrollment_visit') {
        if (this.appt.startDate === null) {
          this.errors.push('PLease select a time for the visit.')
        }
      }

      if (
        !(
          this.primaryContact.phones.home ||
          this.primaryContact.phones.mobile ||
          this.primaryContact.phones.work ||
          this.primaryContact.email
        )
      ) {
        this.errors.push('PLease provide a method of contact.')
      }

      if (!this.errors.length) {
        this.$refs.modalSscConfirmation.show()
      } else {
        this.$bvModal.msgBoxOk(this.errors.join('<br>'))
        // this.showWarningMsg({message: this.errors.join('<br>')})
      }
    },
    /**
     * resets the form back to its original state
     */
    onFormReset() {
      this.$bvModal.msgBoxConfirm('Reset the current form?').then((confirm) => {
        if (!confirm) {
          return
        }
        this.formReset()
      })
    },
    formReset() {
      this.firstSearchResults = []
      this.firstSearchOptions = []
      this.queryFirstSearchResults = null
      this.queryPostalCode = null

      this.$emit('reference-id', null)
      this.$emit('franchise-code', null)
      this.$emit('client-id', null)
      this.$emit('lead-id', null)
      this.$emit('reset')
    },
    /**
     * sets current date and time in appt details
     */
    setCurrentDateTime() {
      const dataTime = this.$d().format().split('T')
      // console.log('setCurrentDateTime', dataTime)
      this.appt.startDate = dataTime[0]
      this.appt.startTime = dataTime[1].split('-')[0] // @TODO ensure 15 min interval
    },
  },
}
</script>

<style scoped>
#ssc-appt-form fieldset {
  margin-bottom: 23px;
}
.form-group {
  margin-bottom: 0.5rem !important;
}
.ssc-form-reset {
  position: absolute;
  top: 10px;
  right: 10px;
}
.container-errors {
  position: fixed;
  top: 0;
  left: 25%;
  right: 25%;
  z-index: 999999;
  text-align: center;
  padding-top: 14px;
}
@media (max-width: 768px) {
  .container-lookup {
    position: absolute;
    top: 0;
    min-height: 50px;
    width: 99%;
    z-index: 999;
  }
}
</style>
