<template>
  <b-row>
    <b-col cols="12" md="4" class="py-4">
      <b-form-group>
        <b-form-radio-group
          v-model="firstSearchMethod"
          :options="firstSearchMethods"
          @change="$refs.autocomplete.$el.focus()"
          autofocus
        ></b-form-radio-group>
      </b-form-group>

      <!-- first search (postal code, client or franchise) -->
      <b-row no-gutters class="mb-2">
        <b-col v-if="firstSearchMethod === 'postal_code'" cols="3">
          <b-form-select v-model="countryCode">
            <b-form-select-option value="US">US</b-form-select-option>
            <b-form-select-option value="CA">CA</b-form-select-option>
            <b-form-select-option value="TK">TK</b-form-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="9">
          <b-form-group
            id="fieldset-search-query"
            :label="firstSearchLabel"
            label-for="search-query"
            label-sr-only
          >
            <v-autocomplete
              ref="autocomplete"
              :auto-select-one-item="firstSearchAutoSelect"
              :minLen="firstSearchMinLen"
              input-class="form-control"
              v-model="queryFirstSearchResults"
              :items="firstSearchOptions"
              :get-label="selectedFirstSearchResult"
              :component-item="aCTemplate"
              :input-attrs="{ placeholder: firstSearchPlaceholder }"
              @update-items="updateFirstSearchOptions"
              @change="$emit('change', $event)"
              debounce="350"
              id="search-query"
              keep-open
            ></v-autocomplete>
          </b-form-group>
        </b-col>
      </b-row>
    </b-col>
    <b-col cols="12" md="8" class="py-4">
      <!-- map -->
      <PCMap
        :postalQuery="queryPostalCode"
        :center="gMapCenter"
        :zoom="defMapZoom"
        type="terrain"
        style="width: 100%; height: 500px"
      />
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import AutoCompleteItemTemplate from "@/components/AutoComplete/AutoCompleteItemTemplate.vue";
import PCMap from "@/components/PCMap.vue";

export default {
  name: "FirstSearch",
  components: { PCMap },
  props: {
    firstSearchMethodDefault: {
      type: String,
      default: "postal_code",
    },
  },
  data() {
    return {
      countryCode: "US",
      searchText: null,

      // googlemap
      defMapZoom: 14,
      gMapCenter: { lat: 39.8283, lng: -98.5795 },
      queryPostalCode: null,
      aCTemplate: AutoCompleteItemTemplate,

      // initial lookup option (postal code, student or franchise)
      firstSearchMethods: [
        {
          value: "postal_code",
          text: this.$t("label.zipCode"),
          placeholder: this.$t("label.zipCode"),
        },
        {
          value: "client",
          text: this.$t("label.name"),
          placeholder: `${this.$t("label.name")} / ${this.$t("label.phoneNumber")}`,
        },
        {
          value: "franchise",
          text: this.$t("label.franchise"),
          placeholder: `${this.$t("label.franchise")} ${this.$t(
            "common.director"
          )} / ${this.$t("common.code")}`,
        },
        {
          value: "appt_id",
          text: this.$t("label.appointment"),
          placeholder: this.$t("label.referenceId"),
        },
      ],

      // postal code lookup data
      firstSearchMethod: null,
      firstSearchAutoSelect: false,
      firstSearchMinLen: 3,
      firstSearchOptions: [],
      firstSearchSelected: null,
      // queryFirstSearch: null,
      queryFirstSearchResults: null,
      iniVal: "",

      searchLeads: true,
    };
  },
  computed: {
    ...mapGetters(["searchResults"]),
    firstSearchLabel() {
      for (let k in this.firstSearchMethods) {
        if (this.firstSearchMethod === this.firstSearchMethods[k].value) {
          return this.firstSearchMethods[k].text;
        }
      }
      return ''
    },
    firstSearchPlaceholder() {
      for (let k in this.firstSearchMethods) {
        if (this.firstSearchMethod === this.firstSearchMethods[k].value) {
          return this.firstSearchMethods[k].placeholder;
        }
      }
      return ''
    },
  },
  methods: {
    ...mapActions(["systemSearch"]),
    /**
     * first search methods
     */
    updateFirstSearchOptions(text) {
      this.queryFirstSearchResults = null;
      this.firstSearchOptions = [];
      if (!text) {
        return;
      }
      this.searchText = text;
      switch (this.firstSearchMethod) {
        case "postal_code":
          this.updatePostalCodeOptions();
          break;
        case "client":
          this.updateClientOptions();
          break;
        case "franchise":
          this.updateFranchiseOptions();
          break;
        case "appt_id":
          this.updateRefIdOptions();
      }
    },
    /**
     * postal code method
     */
    async updatePostalCodeOptions() {
      const text = this.searchText;
      this.firstSearchOptions = [];
      if (text === "" || text.length < 4) return;
      await this.systemSearch({
        search: "postal_codes",
        query: text,
      });

      let results = [...this.searchResults];

      // let results = results // .postal_codes
      if (results.length > 0) {
        this.queryPostalCode = text; // view in Google Maps
        for (let k in results) {
          if (results[k].status === 3) {
            // 3 = requested
            continue;
          }
          let user = results[k].franchise.director[0];
          let option = {
            id: results[k].franchise.franchiseCode,
            name: results[k].zipCode,
            description:
              " | " +
              `${user.profile.fname} ${user.profile.lname} (#${results[k].franchise.franchiseCode})` +
              " | " +
              results[k].status.status.charAt(0) +
              " | " +
              results[k].bookedAppts,
          };
          this.firstSearchOptions.push(option);
        }
      }

      if (!this.firstSearchOptions.length) {
        let option = {
          id: null,
          name: text,
          description: "Unserviced",
        };
        this.firstSearchOptions.push(option);
      }
    },
    /**
     * client method
     */
    async updateClientOptions() {
      this.firstSearchOptions = [];
      let clients = [];
      let leads = [];
      if (this.searchText === "") {
        return;
      }
      // get clients
      await this.systemSearch({
        search: !isNaN(this.searchText) ? "client_phones" : "clients",
        query: this.searchText,
      });
      clients = this.searchResults.map((e) => ({
        id: e.client.id,
        type: "client",
        franchise: e.franchiseCode,
        name: `${e.client.fname} ${e.client.lname}`,
        description:
          "(#" +
          e.franchiseCode +
          " client" /*e.type*/ +
          ") " +
          (e.client.street1 || "") +
          ` ${e.client.zipCode}`, // +
        // (e.last_franchise ? ' (fc: ' + e.last_franchise + ')' : '')
      }));
      if (this.searchLeads) {
        // get leads
        await this.systemSearch({
          search: "leads",
          query: this.searchText,
        });
        leads = this.searchResults.map((e) => ({
          id: e.id,
          type: "lead",
          franchise: e.franchiseCode,
          name: `${e.fname} ${e.lname}`,
          description:
            "(#" + e.franchiseCode + " lead" /*e.type*/ + ") " + e.city ||
            "" + ` ${e.zipCode}`, // +
          // (e.last_franchise ? ' (fc: ' + e.last_franchise + ')' : '')
        }));
      }
      this.firstSearchOptions = leads.concat(clients);
    },
    /**
     * franchise method
     */
    async updateFranchiseOptions() {
      this.firstSearchOptions = [];
      const text = this.searchText;
      if (text === "") return;

      await this.systemSearch({
        search: "franchises",
        query: text,
      });
      let results = [...this.searchResults];

      // results = results.franchises
      if (results.length > 0) {
        for (let k in results) {
          if (results[k].franchiseCode) {
            let option = {
              id: results[k].franchiseCode,
              name: `${results[k].director.profile.fname} ${results[k].director.profile.lname}`,
              description: "(#" + results[k].franchiseCode + ")",
            };
            this.firstSearchOptions.push(option);
          }
        }
      }
    },
    /**
     * reference ID method
     */
    async updateRefIdOptions() {
      this.firstSearchOptions = [];
      const text = this.searchText;
      if (text === "") return;

      await this.systemSearch({
        search: "appointments",
        query: text,
      });
      let results = this.searchResults;

      // results = results.appointments
      if (results.length > 0) {
        for (let k in results) {
          let option = {
            id: results[k].id,
            name: results[k].id,
            franchise: results[k].franchiseCode,
            clientId: results[k].client.id,
            description:
              `${results[k].client.fname} ${results[k].client.lname}` +
              (results[k].address1 ? " at " + results[k].address1 : "") +
              ` ${results[k].zipCode}` +
              " (#" +
              results[k].franchiseCode +
              ")",
          };
          this.firstSearchOptions.push(option);
        }
      }
    },
    /**
     * called when user clicks an option
     */
    selectedFirstSearchResult(item) {
      if (item === null) {
        return "";
      }

      let res = item;
      res.method = this.firstSearchMethod;
      res.value = item.name;
      /* {
        id: item.id,
        method: this.firstSearchMethod,
        value: item.name
      } */

      if (this.firstSearchMethod === "client") {
        res.method = item.type !== "lead" ? "client" : "lead";
      }

      this.$emit("selected-value", res);

      /*if (this.firstSearchMethod === 'postal_code' && item.name !== null) {
        // POSTAL CODE
        this.$emit('postal-code', `${item.id}|${item.name}`) // fc|pc e.g., 1900|33648

      } else if (this.firstSearchMethod === 'franchise' && item.id !== null) {
        // FRANCHISE
        this.$emit('selected-franchise', item.id)

      } else if (this.firstSearchMethod === 'client') {
        // CLIENT
        if (item.type !== 'lead') {
          this.$emit('selected-client', item.id)
        } else {
          this.$emit('selected-lead', item.id)
        }

      } else if (this.firstSearchMethod === 'appt_id' && item.id !== null) {
        // APPT REFERENCE ID
        this.$emit('reference-id', item.id)

      }
      return ''*/
      // return item.name
    },
  },
  watch: {
    firstSearchMethod() {
      // @TODO: clear search text
      this.selectedFirstSearchResult(null);
      this.queryFirstSearchResults = null;
      this.firstSearchOptions = null;
      this.updateFirstSearchOptions("");
    },
    queryFirstSearchResults(item) {
      if (item !== null && this.firstSearchMethod === "postal_code") {
        this.$emit("postal-code", item.name);
      }
    },
  },
  created() {
    this.firstSearchMethod = this.firstSearchMethodDefault;
    // @XXX for testing
    // this.searchText = '33648'
    // this.updatePostalCodeOptions()
  },
};
</script>

<style lang="scss">
#fieldset-search-query .v-autocomplete {
  position: relative !important;
}

#fieldset-search-query .v-autocomplete-list {
  position: absolute !important;
  top: 36px;
  left: 0;
  width: 100%;
  z-index: 999999;
}

#fieldset-search-query .v-autocomplete-list-item {
  background-color: #ffff;
}
</style>
