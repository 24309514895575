export const sscConstants = Object.freeze({
  STAFF_GROUP: 'ec',
  STAFF_STATUS_ACTIVE: 2,
  SERVICE_GROUP_OPTION_NULL: [{value: null, text: 'Service Group'}],
  DEFAULT_CLIENT: {
    id: null,
    status: 'Prospect',
    source: null,
    street1: null,
    street2: null,
    city: null,
    state: null,
    zipCode: null,
    country: 'US',
    gate_code: null,
    franchiseCode: null,
    phone: {
      home: null,
      mobile: null,
      work: null
    },
    email: null,
    timezone: null,
    primary_contact: {
      relationship: null,
      fname: null,
      lname: null
    }
  },
  SSC_RESULT_OPTIONS: [
    {value: null, text: 'Reason not boooked'},
    {value: '2', text: 'Calling for EC / Tutor / Director'},
    {value: '3', text: 'No EC Available'},
    {value: '4', text: 'Subject not Provided'},
    {value: '5', text: 'Calling for location'},
    {value: '20', text: 'Outside Serviceable Area'},
    {value: '22', text: 'Director Does Not Use SSC'},
    {value: '23', text: 'Missing Franchise Data'},
    {value: '24', text: 'General info. No rates given'},

    {value: '1', text: 'Price Objection'},
    {value: '25', text: 'Speak w/ spouse/child'},
    {value: '26', text: 'Shopping'}
  ],
  PRIMARY_CONTACT_RELATIONSHIPS: [
    {value: 'Mother', text: 'Mother'},
    {value: 'Father', text: 'Father'},
    {value: 'Grand Mother', text: 'Grand Mother'},
    {value: 'Grand Father', text: 'Grand Father'},
    {value: 'Other Guardian', text: 'Other Guardian'},
    {value: 'Self', text: 'Self'},
    {value: 'Other', text: 'Other'}
  ],
  SAVE_OPTIONS_NEW: [
    { value: "enrollment", text: "Booked Enrollment", class: "bg-success" },
    {
      value: "enrollment_visit",
      text: "Enrollment Visit",
      class: "bg-success",
    },
    { value: "client_lead", text: "Non-Booked Lead" },
  ],
  SAVE_OPTIONS_UPDATE: [
    { value: "enrollment", text: "Update Enrollment", class: "bg-success" },
    {
      value: "enrollment_visit",
      text: "Update Enrollment Visit",
      class: "bg-success",
    },
    { value: "enrollment_visit_canceled", text: "Cancel Enrollment Visit" },
  ],
  DEFAULT_APPT: {
    city: '',
    consultation: true,
    country: 'US',
    directorNotes: null,
    directions: null,
    duration: "0",
    franchiseCode: null,
    notes: null,
    promotion: null,
    registrationFee: 0,
    registrationFeeNotes: null,
    resultReason: null,
    startDate: null,
    startTime: null,
    state: null,
    street1: '',
    street2: '',
    zipCode: null
  },
  DEFAULT_PC: {
    relationship: null,
    fname: null,
    lname: null,
    email: null,
    phones: {
      home: { number: null },
      mobile: { number: null },
      work: { number: null }
    }
  }
})
