<template>
<div class='google-map' :id='mapName'></div>
</template>

<script>
import { mapGetters } from 'vuex'
const { Loader } = require('google-maps')

export default {
  name: 'PCMap',
  props: {
    name: {
      default () {
        return 'pc'
      }
    },
    postalQuery: {
      default () {
        return null
      }
    },
    zoom: {
      default () {
        return 12
      }
    },
    lookup: {
      default () {
        return true
      }
    }
  },
  data () {
    return {
      loader: null,
      mapName: this.name + '-map',
      pCMapObj: {},
      infoWindows: [],
      addMarker: false,
      markers: [],
      territories: null,
      pCMapZipSelector: null,
      pCMapZipBtn: null,
      pCMapCenter: {lat: 41.5965, lng: -73.9110},
      pCMapRadius: 15,
      pCMapRadiusMax: 100,
      pCMapColors: ['#641e16', '#512e5f', '#154360', '#0e6251', '#145a32', '#7d6608', '#784212', '#7b7d7d', '#4d5656', '#1b2631']
    }
  },
  computed: mapGetters([
    'territoryCoords',
    'closestTerritories',
    'mapCenter'
  ]),
  methods: {
    zipSearch (e) {
      if (e) { e.preventDefault() }
      this.getTerritoryResponse(this.pCMapZipSelector, true)
    },
    getTerritoryResponse (code/* , addMark */) {
      let lat
      let lng

      if (!code) {
        let center = this.pCMapObj.getCenter()
        lat = center.lat()
        lng = center.lng()
      } else if (typeof code !== 'string') {
        lat = code.lat
        lng = code.lng
      } else {
        lat = code
        lng = ''
      }

      this.$store.dispatch('getClosestTerritories', {
        lat: lat,
        lng: lng
      }).then(() => {
        if (this.lookup) this.updateTerritories()
      })

      // if (addMark) this.markCenter(this.$store.getters.mapCenter)
      // this.updateMap()
    },
    updateTerritories () {
      let zips = {}
      this.territories = Object.assign({}, this.closestTerritories)
      let c = 0 // color key
      zips.franchise = {}

      // iterate through closestTerritories
      for (let fc in this.territories) {
        // add territories to zips array
        if (!zips.franchise[fc]) { zips.franchise[fc] = { /* pc: [], nc: [] */ } }
        if (typeof this.territories[fc].protected !== 'undefined') zips.franchise[fc].protected = this.territories[fc].protected
        if (typeof this.territories[fc].unprotected !== 'undefined') zips.franchise[fc].unprotected = this.territories[fc].unprotected
        if (fc > 0) {
          // set color
          this.territories[fc]['color'] = this.pCMapColors[c]
          c++
          if (c === this.pCMapColors.length - 1) { c = 0 }
        } else {
          // set transparent
          this.territories[fc]['color'] = '#FFFFFF'
        }
      }

      // Get coordinates and update map
      this.$store.dispatch('getTerritoryCoords', zips).then(() => {
        if (this.lookup) this.updateMap()
      })
    },
    updateMap () {
      if (!this.territoryCoords) {
        return
      }
      let featuresDel = ''
      let features = ''
      let zipsData = this.territoryCoords.franchise
      for (let fc in zipsData) {
        for (let code in zipsData[fc]) {
          if (zipsData[fc][code].coords) {
            let thisGroup = this.territories[fc]
            // features JSON string
            features += featuresDel + '{' +
              '"type": "Feature",' +
              // properties
              '"properties": {' +
                '"name": "' + code + '",' +
                '"color": "' + thisGroup.color + '",' +
                '"code": "' + code + '",' +
                '"SD_NAME": "code-' + code + '",' +
                '"director": "' + thisGroup.director + '",' +
                '"franchise": "' + fc + '",' +
                '"phone": "' + thisGroup.phone + '",' +
                '"email": "' + thisGroup.email + '",' +
                '"url": "' + (thisGroup.url ? thisGroup.url.split(/(?:\r\n|\r|\n)/)[0] : '') + '",' +
                '"center": "' + thisGroup.center + '",' +
                '"set": "' + zipsData[fc][code].set + '"' +
              '},' +
              // geometry
              '"geometry": {' +
                '"type": "' + zipsData[fc][code].type + '",' +
                '"coordinates": ' + zipsData[fc][code].coords +
              '}' +
            '}'
            featuresDel = ','
          }
        }
      }

      if (features !== '') {
        features = '[' + features + ']'
        features = JSON.parse(features)
        let FeatureCollection = {'type': 'FeatureCollection', 'features': features}
        this.pCMapObj.data.addGeoJson(FeatureCollection)
      }

      // render each feature, set colors and opacity etc
      this.pCMapObj.data.setStyle(feature => {
        // apply styling to features
        let color = feature.getProperty('color')
        let fillOpacity = '.5'
        if (feature.getProperty('set') === 'unprotected') {
          fillOpacity = '.2'
        }
        return {
          fillColor: color,
          strokeWeight: 1,
          strokeColor: color,
          strokeOpacity: 1,
          fillOpacity: fillOpacity
        }
      })

      // add click event listener for infowindow
      this.pCMapObj.data.addListener('click', event => {
        if (event.feature.getProperty('code') === '0') { return false } // unserviced
        this.closeAllInfoWindows()
        let fc = event.feature.getProperty('franchise')
        let url = event.feature.getProperty('url')
        let contentString = (fc === '0') ? '<div>non-serviced</div>' : '<div>' +
        '<div class="page-header" style="margin:0 0 14px"><h3>' + event.feature.getProperty('director') + '</h3></div>' +
        '<div>' +
        '<p>' + event.feature.getProperty('code') + ' (' + event.feature.getProperty('set') + ')</p>' +
        '<p>Franchise #:' + fc + '</p>' +
        '<p>phone: ' + event.feature.getProperty('phone') + '</p>' +
        '<p>email: ' + event.feature.getProperty('email') + '</p>' +
        (url ? '<p>url: <a href="' + url + '" target="_blank">' + url + '</a></p>' : '') +
        '</div>' +
        '</div>'
        let center = { lat: event.latLng.lat(), lng: event.latLng.lng() }
        
        // GoogleMapsLoader.load((google) => {
        this.loader.load().then((google) => {
          let infowindow = new google.maps.InfoWindow({
            content: contentString,
            position: center
          })
          this.infoWindows.push(infowindow)
          infowindow.open(this.pCMapObj)
        })
      })
    },
    getRaduis () {
      let radius = ''
      let bounds = this.pCMapObj.getBounds()
      let center = this.pCMapObj.getCenter()
      if (bounds && center) {
        // let ne = bounds.getNorthEast()
        // Calculate radius (in meters).
        // GoogleMapsLoader.load((google) => {
        //   radius = google.maps.geometry.spherical.computeDistanceBetween(center, ne)
        // })
        radius = radius * 0.00062137 // convert meters to miles
      }
      if (radius > this.pCMapRadiusMax) radius = this.pCMapRadiusMax
      return radius
    },
    closeAllInfoWindows () {
      for (let i = 0; i < this.infoWindows.length; i++) {
        this.infoWindows[i].close()
      }
    },
    removeMarkers () {
      // remove previous marker(s)
      for (var i = 0; i < this.markers.length; i++) {
        // this.markers[i].setMap(this.pCMapObj)
      }
      this.markers = []
    },
    markCenter (center) {
      let marker
      this.removeMarkers()
      // add new marker
      center = {lat: parseFloat(center[0]), lng: parseFloat(center[1])}
      // GoogleMapsLoader.load(google)
      this.loader.load().then((google) => {
        marker = new google.maps.Marker({
          map: this.pCMapObj,
          position: center
        })
      })
      this.pCMapObj.setCenter(center)
      this.markers.push(marker)
    },
    iniGoogleMap () {
      // pCMap dblclick handler
      this.pCMapObj.addListener('dblclick', () => {
        this.addMarker = true
        // this.getTerritoryResponse({ lat: e.latLng.lat(), lng: e.latLng.lng() }, true)
      })

      // pCMap zoom_changed handler
      this.pCMapObj.addListener('zoom_changed', () => {
        this.addMarker = false
        // this.getTerritoryResponse()
      })

      // pCMap dragend handler
      this.pCMapObj.addListener('dragend', () => {
        this.addMarker = false
        // this.getTerritoryResponse()
      })
    }
  },
  watch: {
    postalQuery (val) {
      if (val) {
        this.addMarker = true
        if (this.lookup) this.getTerritoryResponse(val, true)
      } else {
        this.removeMarkers()
      }
    },
    closestTerritories () {
      // if (val !== null && this.lookup) this.updateTerritories()
    },
    mapCenter (val) {
      if (val !== null && this.addMarker) this.markCenter(val)
    },
    territoryCoords () {
      // if (val !== null && this.lookup) this.updateMap()
    }
  },
  mounted () {

    this.loader = new Loader(process.env.VUE_APP_GOOGLE_MAPS_KEY);
    this.loader.load().then((google) => {
      this.pCMapObj = new google.maps.Map(document.getElementById(this.mapName), {
        zoom: this.zoom,
        center: this.pCMapCenter
      })

      google.maps.event.addDomListenerOnce(this.pCMapObj, 'idle', function () {
        google.maps.event.addDomListener(window, 'resize')
      })

      this.iniGoogleMap()
    });

    /* OLD CODE
    GoogleMapsLoader.KEY = process.env.VUE_APP_GOOGLE_MAPS_KEY
    GoogleMapsLoader.load((google) => {
      this.pCMapObj = new google.maps.Map(document.getElementById(this.mapName), {
        zoom: this.zoom,
        center: this.pCMapCenter
      })

      google.maps.event.addDomListenerOnce(this.pCMapObj, 'idle', function () {
        google.maps.event.addDomListener(window, 'resize')
      })

      this.iniGoogleMap()
    })*/
  },
  created () {
    this.$store.dispatch('resetClosestTerritories')
    this.$store.dispatch('resetTerritoryCoords')
  }
}
</script>

<style scoped>
.google-map {
  width: 800px;
  height: 600px;
  margin: 0 auto;
  background: gray;
}
</style>
