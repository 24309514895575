<template>
  <div>
    <template v-for="(note, index) in leadNotes">
      <p v-if="!leadNotesLimit || index <= (iniLength-1)" class="mb-1" :key="note.id">
          <span class="text-muted">{{ $moment(note.timestamp).utcOffset(-5).format('MM/DD/YYYY h:mm a') }}</span> - 
          <span class="text-info">
            <a v-if="note.user" href="javascript:;" @click="$store.commit('SET_USER_PROFILE_ID', note.user.id)">{{ note.user.profile.fname }} {{ note.user.profile.lname }}</a>
            <span v-else>System</span>
          </span> 
          {{ note.note }}
      </p>
    </template>
    <div v-if="leadNotesLimit && (iniLength < leadNotes.length)">
      <small><a href="javascript:;" @click="leadNotesLimit = !leadNotesLimit">Toggle More Lead Notes</a></small>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'LeadNotes',
  props: {
    leadId: {
      type: [Number, String],
      default: null
    },
    iniLength: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      leadNotesLimit: false
    }
  },
  computed: {
    ...mapGetters([
      'leadNotes'
    ])
  },
  methods: {
    ...mapActions([
      'getLeadNotes',
      'resetLeadNotes'
    ]),
    setLeadNotesLimit () {
      this.leadNotesLimit = (this.iniLength !== null)
    }
  },
  watch: {
    iniLength () {
      this.setLeadNotesLimit()
    }
  },
  async mounted () {
    await this.getLeadNotes({
      lead: this.leadId,
      'order[id]': 'desc'
    })
    this.setLeadNotesLimit()
  },
  destroyed () {
    this.resetLeadNotes()
  }
}
</script>
