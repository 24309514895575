<template>
  <b-row>
    <b-col v-if="$store.getters.lead.status" cols="12" md="4" lg="3">
      <p class="mt-2 mb-4">
        <b
          class="text-success border-top border-bottom border-success p-2"
        >Lead Status: {{ $store.getters.lead.status.status }}</b>
      </p>
    </b-col>
    <b-col cols="12" md="8" lg="9">
      <fieldset v-if="leadId">
        <legend> Lead Notes <small><a href="javascript:;" @click="leadNotesShow = !leadNotesShow">toggle</a></small></legend>
        <div class="px-1" :hidden="!leadNotesShow">
          <keep-alive>
            <LeadNotes :leadId="leadId" :iniLength="3" />
          </keep-alive>
        </div>
      </fieldset>
    </b-col>
  </b-row>
</template>
<script>
import LeadNotes from "@/components/lead/LeadNotes"
export default {
  name: 'SscLeadHeader',
  components: { LeadNotes },
  props: {
    leadId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      leadNotesShow: false
    }
  }
}
</script>