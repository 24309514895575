<template>
  <fieldset id="form-appt-client" class="m-0">
    <legend class="d-flex justify-content-between align-items-center">
      {{ $t('label.clientMulti') }}
      <a
        v-if="ini && !formIsVisible"
        class="text-success font-weight-bold"
        href="javascript:;"
        v-b-tooltip.hover.top="`${$t('newClient')}`"
        @click="showForm()"
      >
        <b-icon-plus-square-fill></b-icon-plus-square-fill>
      </a>
    </legend>

    <!-- ADDED CLIENT LIST -->
    <div v-for="(client, index) in clientList" :key="index">
      <transition name="fade" mode="out-in">
        <b-card
          v-if="index !== currentClientKey"
          class="cursor-pointer mb-2"
          v-b-tooltip.hover.top="`${$t('label.editClient')}`"
          @click="editClient(index)"
          no-body
        >
          <b-card-body class="px-2 py-1">
            <b-card-title class="h5 d-flex justify-content-between align-items-center">
              {{ client.fname }} {{ client.lname }}
              <small v-if="client.school"
                >({{
                  franchiseSchools.find(({ id }) => id === client.school).data.name
                }})</small
              >
            </b-card-title>
            <b-card-sub-title v-if="client.serviceGroupData" class="mb-2">
              <span v-html="client.serviceGroupData.customName"></span>
              <small
                >(${{ client.serviceGroupData.price }}/{{
                  client.serviceGroupData.priceDivisor
                }}
                | ${{
                  client.serviceGroupData.regFee
                    ? client.serviceGroupData.regFee.price
                    : defaultRegFee
                }}
                {{ $t('common.registrationAbbr') }})
                <br />
                - {{ client.servicesText }}</small
              >
            </b-card-sub-title>

            <b-card-text
              v-if="client.serviceGroupNotes"
              v-html="$options.filters.nl2br(client.serviceGroupNotes)"
              class="text-info"
            ></b-card-text>
          </b-card-body>
        </b-card>
      </transition>
    </div>

    <!-- CLIENT FORM -->
    <transition name="height" mode="out-in">
      <b-card v-if="formIsVisible" class="m-0 mb-2" border-variant="info" no-body>
        <b-card-body class="p-2">
          <!-- CLIENT NAME -->
          <b-form-group
            :label="$t('label.firstName')"
            label-for="client_fname"
            label-sr-only
          >
            <b-form-input
              id="client_fname"
              type="text"
              v-model="clientList[currentClientKey].fname"
              :placeholder="$t('label.firstName')"
              class="mb-2"
              :required="true"
              trim
            ></b-form-input>
          </b-form-group>
          <b-form-group
            :label="$t('label.lastName')"
            label-for="client_lname"
            label-sr-only
          >
            <b-form-input
              id="client_lname"
              type="text"
              v-model="clientList[currentClientKey].lname"
              :placeholder="$t('label.lastName')"
              class="mb-2"
              :required="true"
              trim
            ></b-form-input>
          </b-form-group>

          <!-- CLIENT SCHOOL -->
          <label class="sr-only" for="client_school">School</label>
          <b-form-group
            :label="$t('label.school')"
            label-for="client_school"
            label-sr-only
          >
            <p v-if="!schoolsLoaded">Loading Schools...</p>
            <b-form-select
              v-else
              id="client_school"
              v-model="clientList[currentClientKey].school"
              :options="franchiseSchools"
              text-field="data.name"
              value-field="id"
              class="mb-2"
            >
              <template #first>
                <b-form-select-option :value="null" disabled
                  >-- {{ $t('label.studentSchool') }} --</b-form-select-option
                >
              </template>
              <template #default>
                <b-form-select-option :value="null" class="text-warning">{{
                  schoolNotListed
                }}</b-form-select-option>
              </template>
            </b-form-select>
          </b-form-group>

          <!-- RATE GROUP & SERVICE(S) -->
          <fieldset class="mb-2">
            <!-- RATE GROUP -->
            <legend>{{ $t('label.rateGroup') }} &amp; {{ $t('label.subject') }}</legend>
            <FormApptServiceGroup
              class="mb-2"
              v-model="clientList[currentClientKey].serviceGroup"
              :franchiseCode="franchiseCode"
              :defaultRegFee="defaultRegFee"
              @service-group-data="clientList[currentClientKey].serviceGroupData = $event"
              @service-group-notes="
                clientList[currentClientKey].serviceGroupNotes = $event
              "
              required
            />

            <b-form-group
              id="fieldset-service_group_notes"
              label="Rate Notes (optional)"
              label-for="service_group_notes"
              label-sr-only
            >
              <b-form-textarea
                id="service_group_notes"
                v-model="clientList[currentClientKey].serviceGroupNotes"
                placeholder="Rate Notes (optional)"
                class="mb-2"
                rows="2"
                max-rows="6"
              ></b-form-textarea>
            </b-form-group>

            <!-- SERVICES -->
            <label class="sr-only" for="service">Subject</label>
            <FormApptServices
              v-model="clientList[currentClientKey].services"
              :franchiseCode="franchiseCode"
              :serviceGroup="clientList[this.currentClientKey].serviceGroup"
              multiple
            />
          </fieldset>

          <BaseFormButtons
            tooltip-save="Done with student details"
            tooltip-cancel="Cancel student details"
            label-cancel="Remove"
            label-save="Done"
            @form-button-cancel="cancelNewClient"
            @form-button-submit="saveClient"
            :no-reset="clientList[currentClientKey].id ? true : false"
          />
        </b-card-body>
      </b-card>
    </transition>
  </fieldset>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import FormApptServiceGroup from '@/components/FormApptServiceGroup'
import FormApptServices from '@/components/FormApptServices'

const SCHOOL_NOT_LISTED = 'NOT LISTED'
const DEFAULT_FORM = {
  fname: null,
  id: null,
  key: null,
  lname: null,
  notes: null,
  school: null,
  serviceGroup: null,
  serviceGroupData: null,
  serviceGroupNotes: null,
  services: [],
  servicesText: null,
}

export default {
  name: 'SscApptClient',
  components: { FormApptServiceGroup, FormApptServices /* , Treeselect */ },
  props: {
    franchiseCode: {
      type: String,
      default: null,
    },
    defaultRegFee: {
      type: Number,
      default: 0,
    },
    value: {
      type: Array,
      default: () => [],
    },
    selectedRateGroup: {
      type: String,
      default: null,
    },
    selectedService: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    ini: false,
    schoolsLoaded: false,
    // showClientForm: true,
    clientList: [],
    currentClientKey: null,
  }),
  computed: {
    ...mapGetters([
      // 'activeFranchiseGroupServices',
      'franchiseSchools',
    ]),
    formIsVisible() {
      return this.currentClientKey !== null
    },
    schoolNotListed() {
      return SCHOOL_NOT_LISTED
    },
  },
  methods: {
    ...mapActions([/* 'getActiveFranchiseGroupServices', */ 'getFranchiseSchools']),
    async doGetFranchiseSchools() {
      await this.getFranchiseSchools(this.franchiseCode)
      this.schoolsLoaded = true
    },
    createClientKey() {
      const min = Math.ceil(1000)
      const max = Math.floor(2000)
      return Math.floor(Math.random() * (max - min) + min)
    },
    calcRegistrationFee() {
      if (this.clientList.length === 0) return this.defaultRegFee
      // this.clientList[k].serviceGroupData.regFee.price
      let arr = this.clientList.map((v) => {
        return v.serviceGroupData && v.serviceGroupData.regFee
          ? v.serviceGroupData.regFee.price
          : this.defaultRegFee
      })
      return Math.max.apply(null, arr)
    },
    validateClient() {
      return true
    },
    showForm(index) {
      this.clientList.push({ ...DEFAULT_FORM })
      this.currentClientKey = index || this.clientList.length - 1
      this.clientList[this.currentClientKey].services = []
      this.emitClients()
    },
    async setDefaultClients(clients) {
      if (!clients) clients = [{}]
      for (let i in clients) {
        let key = this.createClientKey()
        let df = DEFAULT_FORM
        if (clients[i].services && clients[i].services.length) delete df.services
        this.clientList[i] = { ...DEFAULT_FORM, ...clients[i], ...{ key } }
      }
      this.currentClientKey = this.clientList.length - 1
      if (this.clientList[this.currentClientKey].serviceGroup) {
        await this.setServiceGroupServices()
        this.saveClient()
      } else {
        this.emitClients()
      }
    },
    setClientData(data) {
      if (!this.clientList[this.currentClientKey].key) {
        const client = { ...this.clientList[this.currentClientKey], ...data }
        this.clientList[this.currentClientKey] = client
      } else {
        const nextKey = this.clientList.length
        const client = { ...this.clientList[nextKey], ...data }
        this.clientList[nextKey] = client
      }
    },
    saveClient() {
      // if (!this.validateClient()) return
      if (this.clientList[this.currentClientKey].services) {
        this.clientList[
          this.currentClientKey
        ].servicesText = this.activeFranchiseGroupServices
          .filter((e) =>
            this.clientList[this.currentClientKey].services.includes(e.service.id)
          )
          .map((e) => e.service.name)
          .join(', ')
      }
      if (!this.clientList[this.currentClientKey].key) {
        this.clientList[this.currentClientKey].key = this.createClientKey()
      }
      this.currentClientKey = null
      this.emitClients()
    },
    editClient(index) {
      if (this.currentClientKey) {
        this.toastError('You cannot simultaneously edit multiple records')
        return
      }
      this.currentClientKey = index
      // this.clientList[currentClientKey] = { ...this.clientList[index] }
      // this.clientList.splice(index, 1)
    },
    cancelNewClient() {
      if (!confirm('Remove this client?')) {
        return
      }
      this.doCancelNewClient()
    },
    doCancelNewClient() {
      const currentClientKey = this.currentClientKey
      this.currentClientKey = null
      this.clientList.splice(currentClientKey, 1)
      this.emitClients()
      // this.resetClientForm()
    },
    setServiceGroup(val) {
      if (val !== this.clientList[this.currentClientKey].serviceGroup) {
        this.clientList[this.currentClientKey].services = []
      }
      this.clientList[this.currentClientKey].serviceGroup = val
    },
    resetClientForm() {
      this.clientList[this.currentClientKey] = null
      this.clientList[this.currentClientKey] = { ...DEFAULT_FORM }
    },
    emitClients() {
      this.$emit('input', this.clientList)
    },
    emitRegistraionFee() {
      this.$emit('registration-fee', this.calcRegistrationFee())
    },
  },
  watch: {
    value: {
      handler(val) {
        this.clientList = val
      },
      deep: true,
    },
    clientList: {
      handler() {
        // this.emitClients()
        this.emitRegistraionFee()
      },
      deep: true,
    },
  },
  async mounted() {
    await this.doGetFranchiseSchools()
    if (this.value.length) {
      this.setDefaultClients()
      if (this.value.length <= 1) {
        this.editClient(0)
      }
    } else {
      this.showForm()
    }
    this.ini = true
    this.emitClients()
  },
}
</script>

<style scoped>
#form-appt-client .card {
  margin: 0.5rem 0 0;
}
#form-appt-client .card:first-child {
  margin: 0;
}
</style>
