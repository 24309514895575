<template>
  <data-table v-bind="tableConfig" no-filter>
    <!-- FILTERS -->
    <template #headerFilters>
      <b-row>
        <b-col v-if="!noFilterId" cols="6" lg="3">
          <b-form-input
            type="search"
            v-model="id"
            placeholder="Reference ID"
            :debounce="gInputDebounce"
          ></b-form-input>
        </b-col>
        <b-col cols="6" lg="3">
          <b-input-group prepend="Status">
            <b-form-select
              v-model="status"
              :options="clientStatusOptions"
              value-field="id"
              text-field="label"
            >
              <template #first>
                <b-form-select-option :value="null">All</b-form-select-option>
              </template>
            </b-form-select>
          </b-input-group>
        </b-col>
        <!-- LAST NAME -->
        <!-- b-col cols="12" lg="3">
          <b-form-input
            type="search"
            v-model="name"
            placeholder="Last Name"
            :debounce="gInputDebounce"
          ></b-form-input>
        </b-col -->
        <!-- CLIENT -->
        <b-col cols="7" lg="4">
          <BaseFormClient v-model="client" no-label />
        </b-col>
        <!-- ZIP CODE -->
        <b-col cols="5" lg="2">
          <b-form-input
            type="search"
            v-model="zipCode"
            placeholder="Zip Code"
            :debounce="gInputDebounce"
          ></b-form-input>
        </b-col>
        <!-- FRANCHISE -->
        <b-col v-if="!(currentFranchise.id || noFilterFranchise)" cols="12" lg="4">
          <BaseFormFranchise @selected-code="franchiseCode = $event" no-label />
        </b-col>
        <!-- DATE SPAN -->
        <b-col cols="12" lg="6">
          <BaseFormDatepicker v-model="dateStart" />
        </b-col>
      </b-row>
    </template>

    <!-- REFERENCE ID -->
    <template #cell(id)="data">
      <b-btn
        size="sm"
        variant="link"
        title="Load Appointment"
        @click="onReferenceIdSelected(data.value)"
        v-b-tooltip.hover
        >{{ data.value }}</b-btn
      >
    </template>

    <!-- CREATED -->
    <template #cell(dateCreated)="data">
      {{ $moment(data.value).format('MM/DD/YYYY') }}
    </template>

    <!-- START DATE -->
    <template #cell(startDate)="data">
      {{ $moment(data.value).format('MM/DD/YYYY') }}
    </template>

    <!-- CREATED -->
    <template #cell(statusLabel)="data">
      <span
        :class="{
          'text-success': data.value === 'complete',
          'text-info': data.value === 'new',
          'text-danger': data.value === 'canceled',
          'text-warning': data.value === 'pending',
        }"
        >{{ data.value }}</span
      >
    </template>

    <!-- CONFIRMED -->
    <template #cell(confirmation)="data">
      <span v-if="data.value" class="text-success"><i class="fa fa-check"></i></span>
      <span v-else class="text-warning">-</span>
    </template>
  </data-table>
</template>

<script>
import DataTable from '@/components/DataTable'
import { constants } from '@/shared/constants'
import { mapGetters } from 'vuex'
export default {
  name: 'ClientAppointments',
  components: { DataTable },
  props: {
    iniFranchiseCode: {
      type: String,
      default: () => null,
    },
    clientId: {
      type: [Number, String],
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
    iniStatus: {
      type: String,
      default: () => null,
    },
    iniDateStart: {
      type: [String, Object],
      default: () => null,
    },
    noFilterId: {
      type: Boolean,
      default: false,
    },
    noFilterFranchise: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    id: null,
    status: null,
    name: null,
    zipCode: null,
    client: null,
    franchiseCode: null,
    dateStart: {
      from: null,
      to: null,
    },
  }),
  computed: {
    ...mapGetters(['clientAppointmentTypes']),
    fields() {
      return [
        { key: 'id', label: 'Ref ID', sortable: true },
        { key: 'client.fname', label: 'First Name', sortable: true },
        { key: 'client.lname', label: 'Last Name', sortable: true },
        { key: 'street1', label: 'Address', sortable: true },
        { key: 'zipCode', label: 'Zip Code', sortable: true },
        { key: 'dateCreated', label: 'Created', sortable: true },
        { key: 'startDate', label: 'Date', sortable: true },
        { key: 'statusLabel', label: 'Status', sortable: true },
        { key: 'confirmation', label: 'Confirmed', sortable: false },
      ]
    },
    clientStatusOptions() {
      return ['pending', 'new', 'complete', 'canceled']
    },
    tableConfig() {
      return {
        uri: constants.API_URI_CLIENT_APPOINTMENTS,
        uriParams: {
          id: this.id,
          franchiseCode: this.franchiseCode,
          'assigned.staff': this.isStaff ? this.currentUser.id : null,
          statusLabel: this.status,
          dateCreated: this.dateCreated,
          'client.id': this.client,
          'client.lname': this.name,
          zipCode: this.zipCode,
          'startDate[after]': this.dateStart.from || null,
          'startDate[before]': this.dateStart.to || null,
          consultation:
            this.type === null
              ? null
              : this.type === this.clientAppointmentTypes.enrollment
              ? true
              : false,
        },
        fields: this.fields,
        customItems: {
          fullName: {
            key: ['client.fname', 'client.lname'],
            label: 'Name',
            sortable: true,
          }, // @TODO
        },
        filterLabel: 'Ref ID / Date',
        sortBy: 'startDate',
        sortDesc: false,
      }
    },
  },
  methods: {
    onReferenceIdSelected(referenceId) {
      this.$emit('referenceIdSelected', referenceId)
    },
  },
  watch: {
    iniFranchiseCode(val) {
      this.franchiseCode = val
    },
  },
  created() {
    this.franchiseCode = this.iniFranchiseCode
    if (typeof this.iniDateStart === 'string') {
      this.dateStart.from = this.iniDateStart
    } else if (typeof this.iniDateStart === 'object') {
      this.dateStart.from = this.iniDateStart.from
      this.dateStart.to = this.iniDateStart.to
    }
    this.status = this.iniStatus
    this.client = this.clientId
  },
}
</script>
