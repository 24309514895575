<template>
  <b-row v-if="franchise">
    <b-col cols="12" lg="3">
      <div class="container-lookup">
        <!-- DIRECTOR DATA -->
        <p>
          {{ $t('label.areaDirector') }}:
          <a
            href="javascript:;"
            @click.prevent="
              $store.commit('SET_USER_PROFILE_ID', franchise.director[0].id)
            "
          >
            {{ franchise.director[0].profile.fname }}
            {{ franchise.director[0].profile.lname }}
          </a>
          (<b-btn
            size="sm"
            variant="link"
            title="View Franchise"
            @click="ModalFranchiseShow(franchise.franchiseCode, true)"
            v-b-tooltip.hover
            >{{ franchise.franchiseCode }}</b-btn
          >)<br />
          <b>{{ franchiseStatus }} <span v-html="franchiseSupport"></span></b>
        </p>
      </div>
    </b-col>
    <b-col cols="12" lg="5">
      <p>
        {{ $t('label.location') }}: {{ franchise.mainArea }}<br />
        {{ $t('label.phoneNumber') }}: {{ franchisePhone }}<br />
        {{ $t('label.email') }}: {{ franchise.email }}
      </p>
    </b-col>
    <b-col cols="12" lg="4">
      <!-- SSC NOTES -->
      <sscNotes :franchiseCode="franchise.franchiseCode" />
    </b-col>
  </b-row>
</template>

<script>
import { constants } from '../../shared/constants'
import sscNotes from './~SscNotes.vue'

export default {
  name: 'SscApptFranchiseInfo',
  components: { sscNotes },
  props: {
    franchise: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      franchiseSupportLevel: constants.SUPPORT_LEVELS,
    }
  },
  computed: {
    franchisePhone() {
      if (!this.franchise.phones.length) {
        return ''
      }
      return this.franchise.phones.map((e) => e.number).join(' / ')
    },
    franchiseStatus() {
      return this.franchise.active ? 'Active' : 'Inactive'
    },
    franchiseSupport() {
      let status = 'danger'
      for (let i in this.franchiseSupportLevel) {
        if (this.franchise.level === this.franchiseSupportLevel[i].id) {
          switch (this.franchiseSupportLevel[i].id) {
            case 0:
              status = 'warning'
              break
            case 3:
              status = 'success'
              break
          }
          return (
            ': <span class="text-' +
            status +
            '">' +
            this.franchiseSupportLevel[i].label +
            '</span>'
          )
        }
      }
      return ''
    },
  },
}
</script>
