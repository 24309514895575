<template>
  <FullCalendar
    ref="timelineScheduler"
    class="fms-scheduler mb-2"
    :options="calendarOptions"
  />
</template>

<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import interactionPlugin from '@fullcalendar/interaction'
// import bootstrapPlugin from '@fullcalendar/bootstrap'

const FULLCALENDAR_SCHEDULER_LICENSE = 'CC-Attribution-NonCommercial-NoDerivatives'
const DEF_RESOURCE_LABEL = 'Resources'
// const DEF_TZ = 'UTC'

export default {
  name: 'Scheduler',
  components: { FullCalendar },
  props: {
    franchiseCode: String,
    editableId: [Number, String],
    date: {
      type: String,
      default: null,
    },
    resourceLabel: {
      type: String,
      default: DEF_RESOURCE_LABEL,
    },
    resources: {
      type: Array,
      default: () => [],
    },
    events: {
      type: Array,
      default: () => [],
    },
    iniScrollTime: {
      type: String,
      default: () => '08:00',
    },
    slotDuration: {
      type: String,
      default: () => '00:15',
    },
    eventDuration: {
      type: String,
      default: () => '2',
    },
  },
  data() {
    return {
      scrollTime: '08:00',
      schedulerEvents: [],
    }
  },
  computed: {
    /* schedulerEvents () {
      return this.events.map(e => {
        if (e.id === this.editableId) {
          e.classNames = [ 'bg-warning' ]
          e.editable = true
        }
        return e
      })
    }, */
    calendarApi() {
      return this.$refs.timelineScheduler.getApi()
    },
    editableKey() {
      if (!this.editableId) return null
      return this.schedulerEvents.findIndex((e) => e.id === this.editableId)
    },
    calendarOptions() {
      const calendarOptions = {
        // timeZone: DEF_TZ,
        initialDate: this.date,
        headerToolbar: {
          left: 'title',
          center: '',
          right: 'today prev,next',
        },
        schedulerLicenseKey: FULLCALENDAR_SCHEDULER_LICENSE, // @TODO set purchased key
        selectable: true,
        editable: false,
        selectOverlap: true,
        plugins: [resourceTimelinePlugin, interactionPlugin],
        initialView: 'resourceTimeline',
        scrollTime: this.scrollTime,
        scrollTimeReset: false,
        slotDuration: this.slotDuration,
        aspectRatio: 1.5,
        resourceAreaHeaderContent: this.resourceLabel,
        resources: this.resources,
        events: this.schedulerEvents,
        /* want to keep this as an option? 
        views: {
          resourceTimelineDay: {
            buttonText: '1-Day',
            slotDuration: '00:15'
          },
          resourceTimelineFiveDay: {
            type: 'resourceTimeline',
            duration: { days: 5 },
            buttonText: '5-Day',
            slotDuration: '00:15'
          }
        }, */

        // callbacks
        select: this.onSelect,
        dateClick: this.onDateClick,
        eventClick: this.onEventClick,
        eventDrop: this.onEventDrop,
        eventResize: this.onEventResize,
        datesSet: this.onDatesSet,
      }
      // Use Boostrap Plugin?
      // if (typeof bootstrapPlugin !== 'undefined') {
      //   calendarOptions.plugins.push(bootstrapPlugin)
      //   calendarOptions.themeSystem = 'bootstrap'
      // }
      return calendarOptions
    },
  },
  methods: {
    infoToEvent() {},
    onSelect(info) {
      this.$emit('select', info)
    },
    onDateClick(info) {
      this.$emit('date-click', info)
      // this.calendarApi.scrollToTime(this.$moment(info.dateStr).format())
      // this.scrollTime = this.$moment(info.dateStr).format()
      /* if (this.editableKey !== null) {
        const resourceId = info.resource._resource.id === 'null' ? null : info.resource._resource.id
        this.schedulerEvents[this.editableKey].resourceId = resourceId
        this.schedulerEvents[this.editableKey].start = info.dateStr
        this.schedulerEvents[this.editableKey].end = this.$d(info.dateStr).add(this.eventDuration, 'hours').format()
      } */
    },
    onEventClick(info) {
      this.$emit('event-click', info)
    },
    onEventDrop(info) {
      this.$emit('event-drop', info)
      /* if (this.editableKey !== null) {
        if (info.newResource) {
          const resourceId = info.newResource._resource.id === 'null' ? null : info.newResource._resource.id
          this.schedulerEvents[this.editableKey].resourceId = resourceId
        }
        this.schedulerEvents[this.editableKey].start = this.$d(this.schedulerEvents[this.editableKey].start).add(info.delta.milliseconds, 'milliseconds').format()
        this.schedulerEvents[this.editableKey].end = this.$d(this.schedulerEvents[this.editableKey].end).add(info.delta.milliseconds, 'milliseconds').format()
      } */
    },
    onEventResize(info) {
      this.emitEventData({
        event: 'event-resize',
        info,
      })
    },
    /* viewRender (view, element) {
      this.start = this.$d(view.start).format()
      this.end = this.$d(view.end).format()
    }, */
    // onEventCreated (event) {},
    onDatesSet(info) {
      this.$emit('dates-set', info)
    },
    setEditableId(id) {
      console.log('setEditableId', id)
    },
    emitEventData(args) {
      const data = {}
      switch (args.event) {
        case 'event-drop':
          // @TODO
          break
        case 'select':
          data.resource = args.info.resource._resource.id
          data.allDay = args.info.allDay
          data.startDate = this.$d(args.info.startStr).format('YYYY-MM-DD')
          data.startTime = this.$d(args.info.startStr).format('HH:mm:ss')
          data.duration = this.eventDuration
          break
      }
      this.$emit(args.event, data)
    },
  },
  watch: {
    /* date (val) {
      let end = this.$d(val).add(1, 'day').format('YYYY-MM-DD')
      this.start = this.$d(val + ' 00:00:00').format()
      this.end = this.$d(end + ' 00:00:00').format()

      if (this.$refs.timelineScheduler) {
        // this.$refs.timelineScheduler.fireMethod('changeView', 'timelineDay', val)
      }
    }, */
    editableId(val) {
      if (this.$refs.timelineScheduler) {
        // this.$refs.timelineScheduler.fireMethod('changeView', 'timelineDay', '2017-06-01')
      }
      this.setEditableId(val)
    },
    events() {
      console.log('events updated')
      this.schedulerEvents = this.events.map((e) => {
        if (e.id === this.editableId) {
          e.classNames = ['bg-warning']
          e.editable = true
        }
        return e
      })
    },
  },
  created() {
    this.scrollTime = this.iniScrollTime
  },
}
</script>

<style>
.fms-scheduler {
  height: 300px;
}
</style>
