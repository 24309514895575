<template>
  <div class="animated fadeIn mb-4">
    <div v-if="errors.length" class="container-errors bg-error text-white">
      <div v-for="(error, index) in errors" :key="index">
        {{ error }}
      </div>
    </div>
    <b-tabs class="mb-2" v-model="tabIndex">
      <b-tab :title="tabLabelMain">
        <component
          :is="activeComponent"
          v-bind="activeComponentBind"
          v-on="activeComponentOn"
        ></component>
      </b-tab>

      <!-- b-tab title="SCD" :disabled="!franchiseCode"></b-tab -->

      <b-tab
        class="p-4"
        :title="$tc('label.enrollment', 2)"
        :disabled="!franchiseCode"
        @click="apptsClicked = true"
      >
        <ClientAppointments
          v-if="apptsClicked && franchiseCode"
          :iniFranchiseCode="franchiseCode"
          @referenceIdSelected="setReferenceId"
          :type="clientAppointmentTypes.enrollment"
          no-filter-id
          no-filter-franchise
        />
      </b-tab>

      <b-tab
        class="p-4"
        :title="$t('label.calendar')"
        :disabled="!franchiseCode"
        @click="calendarClicked = true"
      >
        <Calendar
          v-if="calendarClicked && franchiseCode"
          :franchiseCode="franchiseCode"
          view="month"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { constants } from '@/shared/constants'
import FirstSearch from './~FirstSearch.vue'
import SscAppt from './~SscAppt.vue'
// import Scheduler from './Scheduler'
// import AppointmentsList from './AppointmentsList'
import DataTable from '../../components/DataTable.vue'
import ClientAppointments from '../clients/-ClientAppointments.vue'
import Calendar from '../../components/Calendar.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'Ssc',
  components: {
    FirstSearch,
    SscAppt,
    // Scheduler,
    // AppointmentsList,
    ClientAppointments,
    DataTable,
    Calendar,
  },
  beforeRouteLeave(to, from, next) {
    if (!this.firstSearchQuery) {
      return next()
    }
    const answer = window.confirm('Do you really want to leave?')
    if (answer) {
      next()
    } else {
      next(false)
    }
  },
  props: {
    lead: {
      type: Number,
      default: null,
    },
    appt: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      tabIndex: 0,
      errors: [],

      firstSearchQuery: null,
      fromFirstSearch: null,
      franchiseCode: null,
      postalCode: null,
      clientId: null,
      leadId: null,
      referenceId: null,

      apptData: null,
      apptsClicked: false,
      calendarClicked: false,
      activeComponent: 'FirstSearch',
    }
  },
  computed: {
    ...mapGetters(['clientAppointmentTypes']),
    tabLabelMain() {
      return !this.franchiseCode
        ? this.$t('label.search')
        : (!this.referenceId ? this.$t('label.set') : this.$t('label.update')) +
            ' ' +
            this.$t('label.appointment')
    },
    showAppts() {
      return this.franchiseCode && this.apptsClicked
    },
    activeComponentBind() {
      switch (this.activeComponent) {
        case 'SscAppt':
          return {
            leadId: this.leadId,
            clientId: this.clientId,
            franchiseCode: this.franchiseCode,
            postalCode: this.postalCode,
            referenceId: this.referenceId,
            apptData: this.apptData,
          }
        default:
          return {}
      }
    },
    activeComponentOn() {
      switch (this.activeComponent) {
        case 'SscAppt':
          return {
            'client-id': this.setClientId,
            'lead-id': this.setLeadId,
            'franchise-code': this.setFranchiseCode,
            'reference-id': this.setReferenceId,
            reset: this.onReset,
          }
        default:
          return {
            change: this.setFirstSearchQuery,
            'selected-value': this.setFromFirstSearch,

            'selected-franchise': this.setFranchiseCode,
            'selected-client': this.setClientId,
            'selected-lead': this.setLeadId,
            'postal-code': this.setPostalCode,
            'reference-id': this.setReferenceId,
          }
      }
    },
    enrollmentsConfig() {
      return {
        uri: constants.API_URI_CLIENT_APPOINTMENTS,
        uriParams: {
          franchiseCode: this.franchiseCode,
          'client.id': this.clientId,
          consultation: true,
        },
        fields: [
          { key: 'id', label: 'Ref ID', sortable: true },
          { key: 'client.fname', label: 'First Name', sortable: true },
          { key: 'client.lname', label: 'Last Name', sortable: true },
          { key: 'street1', label: 'Address', sortable: true },
          { key: 'zipCode', label: 'Zip Code', sortable: true },
          { key: 'dateCreated', label: 'Created', sortable: true },
          { key: 'startDate', label: 'Date', sortable: true },
          { key: 'statusLabel', label: 'Status', sortable: true },
        ],
        customItems: {
          fullName: {
            key: ['client.fname', 'client.lname'],
            label: 'Name',
            sortable: true,
          }, // @TODO
        },
        filterLabel: 'Ref ID / Date',
      }
    },
    // calendarConfig () {}
  },
  watch: {
    fromFirstSearch(val) {
      if (val) {
        switch (val.method) {
          case 'postal_code':
            this.setFranchiseCode(val.id)
            this.setPostalCode(val.value)
            break
          case 'franchise':
            this.setFranchiseCode(val.id)
            break
          case 'lead':
            this.setFranchiseCode(val.franchise)
            this.setLeadId(val.id)
            break
          case 'client':
            this.setFranchiseCode(val.franchise)
            this.setClientId(val.id)
            break
          case 'appt_id':
            this.setFranchiseCode(val.franchise)
            this.setReferenceId(val.id)
            break
        }
        if (this.franchiseCode) this.activeComponent = 'SscAppt'
      } else {
        this.activeComponent = 'FirstSearch'
      }
    },
  },
  mounted() {
    if (this.$route.params.lead) {
      this.setLeadId(this.$route.params.lead)
      this.activeComponent = 'SscAppt'
    } else if (this.$route.query.lead) {
      this.setLeadId(parseInt(this.$route.query.lead))
      this.activeComponent = 'SscAppt'
    }
  },
  methods: {
    setFirstSearchQuery(val) {
      this.firstSearchQuery = val
    },
    setFromFirstSearch(val) {
      this.fromFirstSearch = val
      // add result to object
      /*this.fromFirstSearch = val.split("|").reduce(function(obj, str, index) {
        let key
        switch (index) {
          case 0: key = 'method'; break
          case 1: key = 'id'; break
          case 2: key = 'value'; break
        }
        obj[key] = str
        return obj;
      }, {});*/
    },

    setFranchiseCode(franchiseCode) {
      this.franchiseCode = franchiseCode
    },
    setClientId(clientId) {
      this.clientId = clientId
    },
    setLeadId(leadId) {
      this.leadId = leadId
    },
    setPostalCode(postalCode) {
      this.postalCode = postalCode
    },
    setReferenceId(referenceId) {
      this.referenceId = referenceId
      this.tabIndex = 0
    },
    setAppt(data) {
      this.referenceId = data.id
      this.tabIndex = 0
    },
    setActiveComponent(val) {
      this.activeComponent = val
    },
    onReset(error) {
      this.activeComponent = 'FirstSearch'
      if (error) {
        this.toastError(error)
      }
    },
  },
}
</script>

<style></style>
